import React from 'react';
import classList from 'react-classlist-helper';
import './SummaryTsandCs.scss';

const SummaryTsandCs = (props) => {
  const classMap = {
    "summary-tandcs": true,
    "layout-wrapper": true,
    [props.className]: !!props.className,
  };

  return (
    <section className={classList(classMap)} >
      <div className="layout-container layout-container--center layout-container--bottom-space">
        <div className='form__privacy'>
        <div className='sumtsandcs'>
            <div className='title'>Summary Terms and Conditions:</div>
              <p>
              UK 18+. 10:00 15/01/24 - 16:59 15/04/24 (‘Promotional Period’). Internet access required. Purchase a Participating Product for your chance to win. Visit <a href="https://www.chunk.cadbury.co.uk" target="_blank" rel="noopener noreferrer">chunk.cadbury.co.uk </a>& register your details. Enter barcode & batch code found on pack. Prizes: Tier 1: 5 x £2,000 cash prize & a Cadbury limited edition vintage-style chunk mug. Tier 2: 200 x Cadbury limited edition vintage-style chunk mug (Designs may vary). Max 1 entry per person per day; max 10 entries across Promotional Period. Proof of purchase required for each entry (retain receipt). Tier 1 prizes awarded via main prize draw at the end of the Promotional Period. Bank account required to claim. Tier 2 prizes awarded instantly via open winning moments. Maximum 1 Tier 1 & 1 Tier 2 prize per person. Enter between 17:00 on 15/04/24 and 16:59 on 18/10/24 to be entered in the final draw on 25/10/24 for the chance to win 1 x £200 cash prize or an unclaimed Tier 2 prize. Prize award subject to availability, entry & claim verification. Go to the website for <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">full T&Cs</a>. Promoter: UK: Mondelez Europe Services GmbH - UK Branch, Cadbury House, Sanderson Road, Uxbridge, UB8 1DH.
              </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SummaryTsandCs;
