import React from 'react';
import classList from 'react-classlist-helper';
import { Parallax } from "react-parallax";

import KvHeadline from 'assets/images/campaign/keyvisual/kv-headline.png';
import KvHeadlineDbl from 'assets/images/campaign/keyvisual/kv-headline@2x.png';
import KvHeadlineDesk from 'assets/images/campaign/keyvisual/kv-headline-desk.png';
import KvHeadlineDeskDbl from 'assets/images/campaign/keyvisual/kv-headline-desk@2x.png';
import KvHeadlineLgDesk from 'assets/images/campaign/keyvisual/kv-headline-lg-desk.png';
import KvHeadlineLgDeskDbl from 'assets/images/campaign/keyvisual/kv-headline-lg-desk@2x.png';

import KvMug from 'assets/images/campaign/keyvisual/kv-mug.png';
import KvMugDbl from 'assets/images/campaign/keyvisual/kv-mug@2x.png';
import KvMugDesk from 'assets/images/campaign/keyvisual/kv-mug-desk.png';
import KvMugDeskDbl from 'assets/images/campaign/keyvisual/kv-mug-desk@2x.png';
import KvMugLgDesk from 'assets/images/campaign/keyvisual/kv-mug-lg-desk.png';
import KvMugLgDeskDbl from 'assets/images/campaign/keyvisual/kv-mug-lg-desk@2x.png';

import KvSteam from 'assets/images/campaign/keyvisual/kv-steam.png';
import KvSteamDbl from 'assets/images/campaign/keyvisual/kv-steam@2x.png';
import KvSteamDesk from 'assets/images/campaign/keyvisual/kv-steam-desk.png';
import KvSteamDeskDbl from 'assets/images/campaign/keyvisual/kv-steam-desk@2x.png';
import KvSteamLgDesk from 'assets/images/campaign/keyvisual/kv-steam-lg-desk.png';
import KvSteamLgDeskDbl from 'assets/images/campaign/keyvisual/kv-steam-lg-desk@2x.png';

import KvBackground from 'assets/images/campaign/keyvisual/kv-bg.png';
import KvBackgroundDbl from 'assets/images/campaign/keyvisual/kv-bg@2x.png';
import KvBackgroundDesk from 'assets/images/campaign/keyvisual/kv-bg-desk.png';
import KvBackgroundDeskDbl from 'assets/images/campaign/keyvisual/kv-bg-desk@2x.png';
import KvBackgroundLgDesk from 'assets/images/campaign/keyvisual/kv-bg-lg-desk.png';
import KvBackgroundLgDeskDbl from 'assets/images/campaign/keyvisual/kv-bg-lg-desk@2x.png';

import KvBadge from 'assets/images/campaign/keyvisual/kv-badge.png';
import KvBadgeDbl from 'assets/images/campaign/keyvisual/kv-badge@2x.png';
import KvBadgeTab from 'assets/images/campaign/keyvisual/kv-badge-tab.png';
import KvBadgeTabDbl from 'assets/images/campaign/keyvisual/kv-badge-tab@2x.png';
import KvBadgeDesk from 'assets/images/campaign/keyvisual/kv-badge-desk.png';
import KvBadgeDeskDbl from 'assets/images/campaign/keyvisual/kv-badge-desk@2x.png';

import KvTexture from 'assets/images/campaign/keyvisual/filters/texture.png';
import KvTextureDbl from 'assets/images/campaign/keyvisual/filters/texture@2x.png';
import KvTextureDesk from 'assets/images/campaign/keyvisual/filters/texture-desk.png';
import KvTextureDeskDbl from 'assets/images/campaign/keyvisual/filters/texture-desk@2x.png';
import KvTextureLgDesk from 'assets/images/campaign/keyvisual/filters/texture-lg-desk.png';
import KvTextureLgDeskDbl from 'assets/images/campaign/keyvisual/filters/texture-lg-desk@2x.png';

import KvVignetteDesk from 'assets/images/campaign/keyvisual/filters/vignette-desk.png';
import KvVignetteDeskDbl from 'assets/images/campaign/keyvisual/filters/vignette-desk@2x.png';
import KvVignetteLgDesk from 'assets/images/campaign/keyvisual/filters/vignette-lg-desk.png';
import KvVignetteLgDeskDbl from 'assets/images/campaign/keyvisual/filters/vignette-lg-desk@2x.png';

import KvGrain from 'assets/images/campaign/keyvisual/filters/grain.png';
import KvGrainDbl from 'assets/images/campaign/keyvisual/filters/grain@2x.png';
import KvGrainDesk from 'assets/images/campaign/keyvisual/filters/grain-desk.png';
import KvGrainDeskDbl from 'assets/images/campaign/keyvisual/filters/grain-desk@2x.png';
import KvGrainLgDesk from 'assets/images/campaign/keyvisual/filters/grain-lg-desk.png';
import KvGrainLgDeskDbl from 'assets/images/campaign/keyvisual/filters/grain-lg-desk@2x.png';

import FadeIn from 'components/FadeIn/FadeIn';

import './KeyVisual.scss';


export default (props) => {

  const classes = {
    "keyvis": true,
  }

  const offsetPercentage = (percentage) => {
    return percentage - 200;
  }

  return (
    <div className={classList(classes)}>
      <Parallax 
        strength={500}
        renderLayer={percentage => (
          <div className='keyvis-wrapper'>
            
            <div className="keyvis__bg"
              style={{
                transform: `translate3d(0, ${offsetPercentage(percentage * 200)}px, 0)`,
              }}
            >

              <picture>    
                <source media="(min-width: 1400px)" srcSet={KvBackgroundLgDeskDbl + " 2x, " + KvBackgroundLgDesk + " 1x"} type="image/png" />
                <source media="(min-width: 640px)" srcSet={KvBackgroundDeskDbl + " 2x, " + KvBackgroundDesk + " 1x"} type="image/png" />
                <img src={KvBackground} srcSet={KvBackgroundDbl + " 2x, " + KvBackground + " 1x"}/>  
              </picture> 

            </div>

            <picture>    
              <source media="(min-width: 1400px)" srcSet={KvBadgeDeskDbl + " 2x, " + KvBadgeDesk + " 1x"} type="image/png" />
              <source media="(min-width: 640px)" srcSet={KvBadgeTabDbl + " 2x, " + KvBadgeTab + " 1x"} type="image/png" />
              <img src={KvBadge} srcSet={KvBadgeDbl + " 2x, " + KvBadge + " 1x"} alt="Cadbury - Yours for 200 years" 
              className='keyvis__badge' />  
            </picture> 
            <FadeIn className='keyvis__headline'>
              
              <picture>    
                <source media="(min-width: 1400px)" srcSet={KvHeadlineLgDeskDbl + " 2x, " + KvHeadlineLgDesk + " 1x"} type="image/png" />
                <source media="(min-width: 640px)" srcSet={KvHeadlineDeskDbl + " 2x, " + KvHeadlineDesk + " 1x"} type="image/png" />
                <img src={KvHeadline} srcSet={KvHeadlineDbl + " 2x, " + KvHeadline + " 1x"} alt="Hot Chocolate 200 - Win a chunk of history" className='' />  
              </picture> 
            
            </FadeIn>

            <picture>   
              <source media="(min-width: 1400px)" srcSet={KvMugLgDeskDbl + " 2x, " + KvMugLgDesk + " 1x"} type="image/png" />
              <source media="(min-width: 640px)" srcSet={KvMugDeskDbl + " 2x, " + KvMugDesk + " 1x"} type="image/png" />
              <img src={KvMug} srcSet={KvMugDbl + " 2x, " + KvMug + " 1x"} alt="Cadbury Chocolate Chunk Mug" 
              className='keyvis__mug'/>  
            </picture>

            <picture>    
                <source media="(min-width: 1400px)" srcSet={KvSteamLgDeskDbl + " 2x, " + KvSteamLgDesk + " 1x"} type="image/png" />
                <source media="(min-width: 640px)" srcSet={KvSteamDeskDbl + " 2x, " + KvSteamDesk + " 1x"} type="image/png" />
                <img src={KvSteam} srcSet={KvSteamDbl + " 2x, " + KvSteam + " 1x"} alt="Steam" 
                className='keyvis__steam'/>  
              </picture>

           
            <picture>    
              <source media="(min-width: 1400px)" srcSet={KvTextureLgDeskDbl + " 2x, " + KvTextureLgDesk + " 1x"} type="image/png" />
              <source media="(min-width: 640px)" srcSet={KvTextureDeskDbl + " 2x, " + KvTextureDesk + " 1x"} type="image/png" />
              <img src={KvTexture} srcSet={KvTextureDbl + " 2x, " + KvTexture + " 1x"} class="keyvis__texture"/>  
            </picture> 

            <picture>    
              <source media="(min-width: 1400px)" srcSet={KvVignetteLgDeskDbl + " 2x, " + KvVignetteLgDesk + " 1x"} type="image/png" />
              <img src={KvVignetteDesk} srcSet={KvVignetteDeskDbl + " 2x, " + KvVignetteDesk + " 1x"} class="keyvis__vignette"/>    
            </picture> 

            <picture>    
              <source media="(min-width: 1400px)" srcSet={KvGrainLgDeskDbl + " 2x, " + KvGrainLgDesk + " 1x"} type="image/png" />
              <source media="(min-width: 640px)" srcSet={KvGrainDeskDbl + " 2x, " + KvGrainDesk + " 1x"} type="image/png" />
              <img src={KvGrain} srcSet={KvGrainDbl + " 2x, " + KvGrain + " 1x"} class="keyvis__grain"/>  
            </picture> 
          </div>
   
        )}
      />
     
    </div>
    
  )
}