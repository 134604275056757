import React from 'react';
import Link from 'components/Link/Link';

export default () => {
  
    return (
      <section className="layout-general layout-wrapper">
        <div className="layout-container layout-container--wide layout-container--center ">
        <div className='form__intro'>
          <h1 className="type-h1">Not Found</h1>
          <p>The page you are looking for could not be found.</p>
        </div>
        <Link 
          to="/" lozenge>
          Go To Homepage
        </Link>
        </div>
      </section>
    );
}