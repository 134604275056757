import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import SettingsContext from 'contexts/SettingsContext';

import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import CodeEntry from './CodeEntry';
import Step from 'components/Step/Step';
import Link from 'components/Link/Link';
import Button from 'components/Button/Button';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';


import KeyVisual from './KeyVisual';

import How1 from 'assets/images/campaign/how-1.png';
import How1_2x from 'assets/images/campaign/how-1@2x.png';
import How1_2 from 'assets/images/campaign/how-1-2.jpg';
import How1_2_2x from 'assets/images/campaign/how-1-2@2x.jpg';
import How2 from 'assets/images/campaign/how-2.png';
import How2_2x from 'assets/images/campaign/how-2@2x.png';
import How3 from 'assets/images/campaign/how-3.png';
import How3_2x from 'assets/images/campaign/how-3@2x.png';
import How3Mopup from 'assets/images/campaign/how-3Mopup.png';
import How3Mopup_2x from 'assets/images/campaign/how-3Mopup@2x.png';
import How4 from 'assets/images/campaign/how-4.png';
import How4_2x from 'assets/images/campaign/how-4@2x.png';
import How4Mopup from 'assets/images/campaign/how-4Mopup.png';
import How4Mopup_2x from 'assets/images/campaign/how-4Mopup@2x.png';


import analyticsService from 'services/analyticsService';

import './Home.scss';

export default () => {
  const [settings, setSettings ] = React.useContext(SettingsContext);
  let history = useHistory();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";
  let competitionIsClosed = compState === "Closed";
  let competitionIsNotStarted= compState === "NotStarted";
  const [showEntryModal, setShowEntryModal] = useState(false);


  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });


  const handleEnterNow = async () => {
      analyticsService.logEvent("enterCompetition", "ProofOfPurchase", {
        action: "OpenProofOfPurchaseModal",
        label: "open modal"
      });
      setShowEntryModal(true);
  }

  const renderSteps = (steps) => {
    return steps.map((step, index) => {
      return (
        <Step key={step.number} {...step} />
      )
    })
  }

  const renderEntryCta = () => {
    return <Button 
      wide
      type="submit" 
      title="Enter" 
      onClick={handleEnterNow}
    />
  }

  const renderIntroMessage = (compState) => {
    switch (compState) {
      case 'Open':
        return <>
          <div className={!competitionIsMopup ? "home__intro" : "home__intro home__intro--wide"}>
            { !competitionIsMopup ? (
              <>
                <div className="type-h1  type-h1--primary">
                  Win £2000 and a chunk of history!                
                </div>
                <div className="home__intro-body">
                  <p>
                  To celebrate 200 years of being the nation's favourite, we're offering you a chance to win a limited edition chunk mug or 1 of 5 £2000 cash prizes.
                  </p>
                  <p>
                  Just enter the barcode from your Cadbury Hot Chocolate (original or instant) to take part.
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="type-h1  type-h1--primary">
                  There's still a chance to win £200              
                </div>
                <div className="home__intro-body">
                  <p>
                  Our sensational prizes have all been snapped up… but there's still a chance to make a prize yours, with a fabulous £200 up for grabs!
                  </p>
                  <p>
                  Enter the barcode and batch code from your participating Cadbury Drinking Hot Chocolate or Instant Hot Chocolate to enter the final draw.
                  </p>
                  </div>
              </>
            )}
          </div>
          <div className="home__cta">
            { renderEntryCta() }
          </div>
        </>

      case 'NotStarted':
        return <>
          <div className="home__intro">
            <div className="type-h1 type-h1--primary"><span>Coming soon:</span> win £2000 and a chunk of history!</div>
            <div className='home__intro-body'>
              <p>
              You're early! The promotion starts at 10am on 15.01.24 so come back then and enter your barcode and batch code for a chance to win a limited edition chunk mug or 1 of 5 £2000 cash prizes.                
              </p>
            </div>
          </div>
          <div className="home__cta">
            <Link 
              analytics={{evnt: "siteLink", category: "NotStartedPhaseIntroduction", action: "OpenCadburyCoUkWebsite"}}
              href="https://www.cadbury.co.uk/" target="_blank" lozenge title="Go to Cadbury.co.uk Website" >Go to Cadbury.co.uk</Link>
          </div>
        </>

      case 'Closed':
        return <>
          <div className="home__intro">
          <div className="type-h1 type-h1--primary">This promotion is now closed.</div>
            <div className="home__intro-body">
              <p>
              Sorry, you've missed it. But there'll be another promotion soon. Visit our website to see when.</p>
            </div>
          </div>
          <div className="home__cta">
          <Link 
              analytics={{evnt: "siteLink", category: "ClosedPhaseIntroduction", action: "OpenCadburyCoUkWebsite"}}
              href="https://www.cadbury.co.uk/" target="_blank" lozenge title="Go to Cadbury.co.uk Website" >Go to Cadbury.co.uk</Link>
          </div>
        </>      
    }
  }

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Cadbury - Hot Chocolate 200</title>
      </Helmet>
      
      { competitionIsOpen &&
        <>
          <CodeEntry 
            show={showEntryModal}
            onDismiss={() => setShowEntryModal(false)}
            onEntry={() => history.push("/registration") } 
          />
        </>
      }
     
      <h1 className="visually-hidden">Cadbury's Dairy Milk - Hot Chocolate 200</h1>

      <section className="layout-wrapper home__banner">
        <div className="home__banner-container">
          <div className="home__banner-content">
            <KeyVisual/>
          </div>
        </div>
      
        <div className={"home__entrybar"}>
          { renderIntroMessage(compState) }
        </div>
        
      </section>


      { (competitionIsOpen || competitionIsMopup) &&
        <section className="layout-wrapper " id="how-to-play">
          <div className="layout-container layout-container--center">

          {!competitionIsMopup ? (
            <h2 id="howitworks" className="type-h1 type-h1--off-white">How do i enter?</h2>
          ) : (
            <h2 id="howitworks" className="type-h1 type-h1--off-white">how to win...</h2>
          )}
          
            <div className="home__steps">
            
              {
                (

                  !competitionIsMopup ? (
                    renderSteps([
                      { number: 1, title: `It's easy! Buy any participating Cadbury product. See full product list here <a href="/terms-and-conditions#products-list" target="_blank" rel="noopener noreferrer">T&Cs</a>.`, image: How1, image2x: How1_2x, alt: "Cadbury Hot Chocolate products"},
                      { number: 2, title: "Enter its barcode and batch code when prompted.", image: How2, image2x: How2_2x, alt:"A barcode and batch code from a Cadbury Hot Chocolate pack" },
                      { number: 3, title: " You'll instantly find out if you've won or not.", image: How3, image2x: How3_2x, alt:"Cadbury Hot Chocolate Vintage mug" },
                      { number: 4, title: "Then you just have to fill out the claims form to receive your prize. Easy!", image: How4, image2x: How4_2x, alt:"Form graphic." }
                    ])
                  ) : (
                    renderSteps([
                      { number: 1, title: `Buy any participating Cadbury product. See full product list here <a href="/terms-and-conditions#products-list" target="_blank" rel="noopener noreferrer">T&Cs</a>.`, image: How1, image2x: How1_2x, alt: "Cadbury Hot Chocolate products"},
                      { number: 2, title: "Enter your barcode and batch code", image: How2, image2x: How2_2x, alt:"A barcode and batch code from a Cadbury Hot Chocolate pack" },
                      { number: 3, title: "Enter your details", image: How3Mopup, image2x: How3Mopup_2x, alt:"Form graphic." },
                      { number: 4, title: `Winners will be drawn on 25.10.24. Visit our <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">T&Cs</a> for more.`, image: How4Mopup, image2x: How4Mopup_2x, alt:"Win £400" }
                    ])
                  )
                )
                
              }

            </div>
            <div className="home__cta">
              { renderEntryCta() }
            </div>
          </div>
          </section>
         
      }

      {
        !competitionIsClosed &&
        <SummaryTsandCs className={(competitionIsNotStarted ? "bg-off-white": "")} />
      }
      
        
    </div>
  );
}