import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import prizeClaimService from 'services/prizeClaimService';
import prizeClaimValidator from 'validators/prizeClaimValidator';

import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';


import useEffectOnce from 'hooks/useEffectOnce';
import Button from 'components/Button/Button';
import TextField from 'components/Forms/TextField';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import reCaptchaService from 'services/reCaptchaService';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';
import Link from 'components/Link/Link';

import Form, { createOnChangeHandler } from './Form';

import ClaimedImage from 'assets/images/campaign/how-1-no-bg.png';
import ClaimedImage2x from 'assets/images/campaign/how-1-no-bg@2x.png';

import './Claim.scss';
import './Form.scss';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

export default (props) => {
  let settingsContext = useSettingsContext();
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let [settings, setSettings] = React.useContext(SettingsContext);
  let { token } = props;
  let [claim, setClaim] = useState();  
  let [sent, setSent] = useState(false);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [town, setTown] = useState(null);
  let [county, setCounty] = useState(null);
  let [country, setCountry] = useState(null);
  let [postcode, setPostcode] = useState(null); 

  let [recaptcha2, setRecaptcha2] = useState(null);

  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  
  
  const createCommand = () => {
    let command = {
      firstName,
      lastName,
      address1,
      address2,
      town,
      county,
      postcode,
      token,
      recaptcha2,
      phaseId: compPhaseId
    };

    return command;
  }

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }

  useEffect(() => {   
      if(claim != undefined && claim.canClaim != undefined && claim.canClaim === true)
      {
        window.setTimeout(function(){
          try 
          {
            let $grecaptchav2 = document.querySelectorAll('.g-recaptcha-v2')[0];
            if ($grecaptchav2) {
             
                window.grecaptcha.ready(() => {
                    window.grecaptcha.render($grecaptchav2, {
                  'sitekey' : '6LfYXRApAAAAAF81VkaLYQjlut6dl_KUIJggeUlw',
                  'callback': handleReCaptchaV2,
                    'expired-callback': handleReCaptchaV2Expired
                    });
              });
            }
          }catch{}

        }, 1000);
      
      }
    
  }, [claim]);


  useEffect(() => {
    async function fetchData() {
      let result = await prizeClaimService.getPrizeClaim(token);
      setClaim(result);

      setCountry(result.country);
    }

    if (token && !claim) {
      fetchData();
    }

    
  }, [claim, token]);

  if (token && !claim) {
    return (
      <div>Loading...</div>
    )
  }
 

  const onFormSuccess = () => {
    setSent(true);
    settingsContext.setIsClaimSent(true);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    document.body.classList.remove("overflow-hidden-mediumdown");
  }


  if (!claim.canClaim) {
    return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Claim your prize | Cadbury - Hot Chocolate 200</title>
      </Helmet>
        <section className="layout-general layout-wrapper">
          <div className="layout-container layout-container--slim layout-container--center">
            <h1 className="type-h1">Sorry!</h1>
            <p className="form__intro">{claim.error.message}</p>  
          </div>
        </section>
      </>
    )
  }

  return (  
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Claim your prize | Cadbury - Hot Chocolate 200</title>
    </Helmet>
    <section className="layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center claim">
        { !sent ? 
          (
            <>
            <h1 className="type-h1">Claim your prize</h1>
            <p className="form__intro">Please complete your details below.</p>  
            <p className="form__intro">We’ll then check that your win is in line with the terms and conditions of our promotion. If so, your prize will be delivered to your address provided within 28 days of claiming.</p>
              
            
            <Form 
              id="claim"
              submitTitle="Claim"
              formClass="form form--slim"
              onSuccess={onFormSuccess}
              command={{
                model: createCommand,
                service: prizeClaimService.addPrizeClaim
              }}
              validation={{
                validator: prizeClaimValidator,
                onValidation: setValidation
              }}
            >
              
              { validation.errors.hasErrors && validation.acknowledged &&
                <ValidationSummary 
                  errors={validation.errors}
                  handlePropertyErrors={['RecaptchaToken']}
                />
              }
              <fieldset className="form__fieldset">
                <TextFieldCharRestricted
                  maxCharLenght ={60} 
                  label="* First Name"
                  id="firstName"
                  onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
                  value={firstName}
                  hasErrors={!!validation.errors.firstName}
                />
                <ValidationMessage errors={validation.errors.firstName} />
              </fieldset>
              <fieldset className="form__fieldset">
                <TextFieldCharRestricted
                  maxCharLenght ={60} 
                  label="* Last Name"
                  id="lastName"
                  onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
                  value={lastName}
                  hasErrors={!!validation.errors.lastName}
                />
                <ValidationMessage errors={validation.errors.lastName} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="* Address line 1"
                  id="address1"
                  onChange={createOnChangeHandler(validation, 'address1', setAddress1)}
                  value={address1}
                  hasErrors={!!validation.errors.address1}
                />
                <ValidationMessage errors={validation.errors.address1} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="Address line 2 (optional)"
                  id="address2"
                  onChange={createOnChangeHandler(validation, 'address2', setAddress2)}
                  value={address2}
                  hasErrors={!!validation.errors.address2}
                />
                <ValidationMessage errors={validation.errors.address2} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField
                  label="* Town/City"
                  id="town"
                  onChange={createOnChangeHandler(validation, 'town', setTown)}
                  value={town}
                  hasErrors={!!validation.errors.town}
                />
                <ValidationMessage errors={validation.errors.town} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="* County"
                  id="county"
                  onChange={createOnChangeHandler(validation, 'county', setCounty)}
                  value={county}
                  hasErrors={!!validation.errors.county}
                />
                <ValidationMessage errors={validation.errors.county} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextField 
                  label="* Postcode"
                  id="postcode"
                  onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
                  value={postcode}
                  hasErrors={!!validation.errors.postcode}
                />
                <ValidationMessage errors={validation.errors.postcode} />
              </fieldset>                    
             
              <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
              <div class="g-recaptcha-v2" data-sitekey="6LfYXRApAAAAAF81VkaLYQjlut6dl_KUIJggeUlw"
                data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
              <ValidationMessage errors={validation.errors.recaptcha2} /> 

              { validation.errors.hasErrors && !validation.acknowledged &&
                <Modal 
                  panel 
                  hideClose
                  icon={AlertIcon}
                  alt="Warning sign"
                  onDismiss={onDismissModal}
                  onConfirm={onDismissModal}
                  title="Oops">
                  <ValidationSummary 
                    modal
                    defaultMessage="Please check you have entered all fields correctly."
                    errors={validation.errors}
                    handlePropertyErrors={['RecaptchaToken']}
                  />
                  { !validation.errors.globalErrors.length ? (
                      <Button title="Try again" onClick={onDismissModal} />
                    ) : (
                      <Button title="Back to homepage" href="/" />
                    )
                  }
                </Modal>
              }
            </Form>
            
            
            </>
          ) : (
            <>
              <h1 className="type-h1">Thank you!</h1>
              <p className="form__intro">
                Your prize will be winging its way to you shortly.  
                </p>
              <img className="claim__image" src={ClaimedImage} srcSet={ClaimedImage2x + " 2x, " + ClaimedImage + " 1x"}
              alt="Hot Chocolate promotional products." />
              <Link to="https://www.cadbury.co.uk/" lozenge>Go to Cadbury.co.uk</Link>
            </>
          )
        }
        
      </div>
    </section>
    <SummaryTsandCs />
    </>
  );
}