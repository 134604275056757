

const clubShirtsList = [
  { 
    "id": "ars", 
    "club": "Arsenal FC", 
    "style": [
      {
        "styleType" : "Mens",
        "sizes" : [
          {
            "sizeType" : "XS",
            "measures" : ["92-82", "71-75", "82-86"]
          },
          {
            "sizeType" : "S",
            "measures" : ["88-94", "76-82", "87-93"]
          },
          {
            "sizeType" : "M",
            "measures" : ["91-102", "83-90", "94-101"]
          },
          {
            "sizeType" : "L",
            "measures" : ["103-111", "91-99", "102-110"]
          },
          {
            "sizeType" : "XL",
            "measures" : ["112-121", "100-109", "111-119"]
          },
          {
            "sizeType" : "2XL",
            "measures" : ["122-132", "110-121", "120-128"]
          },
          {
            "sizeType" : "3XL",
            "measures" : ["133-144", "122-134", "129-138"]
          },
          {
            "sizeType" : "4XL",
            "measures" : ["148-163"]
          }
        ],
        "sizesVariants" : ["Chest (cm)", "Waist (cm)", "Hips (cm)"]
      },
      {
          "styleType" : "Womens",
          "sizes" : [
            {
              "sizeType" : "XXS",
              "measures" : ["73-76", "57-60", "82-85"]
            },
            {
              "sizeType" : "XS",
              "measures" : ["77-82", "61-66", "82-85"]
            },
            {
              "sizeType" : "S",
              "measures" : ["83-88", "67-72", "92-97"]
            },
            {
              "sizeType" : "M",
              "measures" : ["89-94", "73-78", "98-103"]
            },
            {
              "sizeType" : "L",
              "measures" : ["95-101", "79-85", "104-110"]
            },
            {
              "sizeType" : "XL",
              "measures" : ["102-109", "86-94", "111-117"]
            },
            {
              "sizeType" : "2XL",
              "measures" : ["110-118", "94-104", "118-125"]
            }
          ],
          "sizesVariants" : ["Chest (cm)", "Waist (cm)", "Hips (cm)"]
      }
    ]
  },
  { 
    "id": "che", 
    "club": "Chelsea FC", 
    "style": [
      {
        "styleType" : "Mens",
        "sizes" : [
          {
            "sizeType" : "XS",
            "measures" : ["80-88", "65-73", "80-88", "170-183"]
          },
          {
            "sizeType" : "S",
            "measures" : ["88-96", "73-81", "88-96", "170-183"]
          },
          {
            "sizeType" : "M",
            "measures" : ["96-104", "81-89", "96-104", "170-183"]
          },
          {
            "sizeType" : "L",
            "measures" : ["104-112", "89-97", "104-112", "170-183"]
          },
          {
            "sizeType" : "XL",
            "measures" : ["112-124", "97-109", "112-120", "170-183"]
          },
          {
            "sizeType" : "2XL",
            "measures" : ["124-136", "109-121", "120-128", "170-183"]
          },
          {
            "sizeType" : "3XL",
            "measures" : ["136-148", "121-133", "128-136", "170-183"]
          }
        ],
        "sizesVariants" : ["Chest (cm)", "Waist (cm)", "Hips (cm)", "Height (cm)"]
      },
      {
          "styleType" : "Womens",
          "sizes" : [
            {
              "sizeType" : "XS",
              "measures" : ["76-83", "60-67", "84-91"]
            },
            {
              "sizeType" : "S",
              "measures" : ["83-90", "67-74", "91-98"]
            },
            {
              "sizeType" : "M",
              "measures" : ["90-97", "74-81", "98-105"]
            },
            {
              "sizeType" : "L",
              "measures" : ["97-104", "81-88", "105-112"]
            },
            {
              "sizeType" : "XL",
              "measures" : ["104-114", "88-98", "112-120"]
            },
            {
              "sizeType" : "2XL",
              "measures" : ["114-124", "98-108", "120-128"]
            }
          ],
          "sizesVariants" : ["Bust (cm)", "Waist (cm)", "Hips (cm)"]
      }
    ]
  },
  { 
    "id": "liv", 
    "club": "Liverpool FC", 
    "style": [
      {
        "styleType" : "Mens",
        "sizes" : [
          {
            "sizeType" : "XS",
            "measures" : ["80-88", "65-73", "80-88", "170-183"]
          },
          {
            "sizeType" : "S",
            "measures" : ["88-96", "73-81", "88-96", "170-183"]
          },
          {
            "sizeType" : "M",
            "measures" : ["96-104", "81-89", "94-104", "170-183"]
          },
          {
            "sizeType" : "L",
            "measures" : ["104-112", "89-97", "104-112", "170-183"]
          },
          {
            "sizeType" : "XL",
            "measures" : ["112-124", "97-109", "112-120", "170-183"]
          },
          {
            "sizeType" : "2XL",
            "measures" : ["124-136", "109-121", "120-128", "170-183"]
          },
          {
            "sizeType" : "3XL",
            "measures" : ["136-148", "121-133", "128-136", "170-183"]
          }
        ],
        "sizesVariants" : ["Chest (cm)", "Waist (cm)", "Hips (cm)", "Height (cm)"]
      },
      {
          "styleType" : "Womens",
          "sizes" : [
            {
              "sizeType" : "2XS",
              "measures" : ["76-83", "60-67", "84-91"]
            },
            {
              "sizeType" : "XS",
              "measures" : ["76-83", "60-67", "84-91"]
            },
            {
              "sizeType" : "S",
              "measures" : ["83-90", "67-74", "91-98"]
            },
            {
              "sizeType" : "M",
              "measures" : ["90-97", "74-81", "98-105"]
            },
            {
              "sizeType" : "L",
              "measures" : ["97-104", "81-88", "105-112"]
            },
            {
              "sizeType" : "XL",
              "measures" : ["104-114", "88-98", "112-120"]
            }
          ],
          "sizesVariants" : ["Bust (cm)", "Waist (cm)", "Hips (cm)"]
      }
    ]
  },
  { 
    "id": "mci", 
    "club": "Manchester City", 
    "style": [
      {
        "styleType" : "Mens",
        "sizes" : [
          {
            "sizeType" : "XS",
            "measures" : ["88", "86"]
          },
          {
            "sizeType" : "S",
            "measures" : ["94", "92"]
          },
          {
            "sizeType" : "M",
            "measures" : ["100", "98"]
          },
          {
            "sizeType" : "L",
            "measures" : ["108", "106"]
          },
          {
            "sizeType" : "XL",
            "measures" : ["116", "114"]
          },
          {
            "sizeType" : "2XL",
            "measures" : ["124", "122"]
          }
        ],
        "sizesVariants" : ["Chest (cm)", "Hips (cm)"]
      },
      {
          "styleType" : "Womens",
          "sizes" : [
            {
              "sizeType" : "XS",
              "measures" : ["88", "86"]
            },
            {
              "sizeType" : "S",
              "measures" : ["94", "92"]
            },
            {
              "sizeType" : "M",
              "measures" : ["100", "98"]
            },
            {
              "sizeType" : "L",
              "measures" : ["108", "106"]
            },
            {
              "sizeType" : "XL",
              "measures" : ["116", "114"]
            },
            {
              "sizeType" : "2XL",
              "measures" : ["124", "122"]
            }
          ],
          "sizesVariants" : ["Chest (cm)", "Hips (cm)"]
      }
    ]
  },
  { 
    "id": "mun", 
    "club": "Manchester United", 
    "style": [
      {
        "styleType" : "Mens",
        "sizes" : [
          {
            "sizeType" : "XS",
            "measures" : ["92-82", "71-75", "82-86"]
          },
          {
            "sizeType" : "S",
            "measures" : ["88-94", "76-82", "87-93"]
          },
          {
            "sizeType" : "M",
            "measures" : ["91-102", "83-90", "94-101"]
          },
          {
            "sizeType" : "L",
            "measures" : ["103-111", "91-99", "102-110"]
          },
          {
            "sizeType" : "XL",
            "measures" : ["120-121", "100-109", "111-119"]
          },
          {
            "sizeType" : "2XL",
            "measures" : ["122-132", "110-121", "120-128"]
          },
          {
            "sizeType" : "3XL",
            "measures" : ["133-144", "122-134", "129-138"]
          },
          {
            "sizeType" : "4XL",
            "measures" : ["148-163"]
          }
        ],
        "sizesVariants" : ["Chest (cm)", "Waist (cm)", "Hips (cm)"]
      },
      {
          "styleType" : "Womens",
          "sizes" : [
            {
              "sizeType" : "XXS",
              "measures" : ["73-76", "57-60", "82-85"]
            },
            {
              "sizeType" : "XS",
              "measures" : ["77-82", "61-66", "86-91"]
            },
            {
              "sizeType" : "S",
              "measures" : ["83-88", "67-72", "92-97"]
            },
            {
              "sizeType" : "M",
              "measures" : ["89-94", "73-78", "98-103"]
            },
            {
              "sizeType" : "L",
              "measures" : ["95-101", "79-85", "104-110"]
            },
            {
              "sizeType" : "XL",
              "measures" : ["102-109", "86-94", "111-117"]
            },
            {
              "sizeType" : "2XL",
              "measures" : ["110-118", "94-104", "118-125"]
            }
          ],
          "sizesVariants" : ["Bust (cm)", "Waist (cm)", "Hips (cm)"]
      }
    ]
  },
  { 
    "id": "tot", 
    "club": "Tottenham Hotspur", 
    "style": [
      {
        "styleType" : "Mens",
        "sizes" : [
          {
            "sizeType" : "XS",
            "measures" : ["80-88", "<73", "170-183"]
          },
          {
            "sizeType" : "S",
            "measures" : ["88-96", "73-81", "170-183"]
          },
          {
            "sizeType" : "M",
            "measures" : ["96-104", "81-89", "170-183"]
          },
          {
            "sizeType" : "L",
            "measures" : ["104-112", "89-97", "170-183"]
          },
          {
            "sizeType" : "XL",
            "measures" : ["112-124", "97-109", "170-183"]
          },
          {
            "sizeType" : "2XL",
            "measures" : ["124-136", "109-121", "170-183"]
          },
          {
            "sizeType" : "3XL",
            "measures" : ["136-148", "121-133", "170-183"]
          }
        ],
        "sizesVariants" : ["Chest (cm)", "Waist (cm)", "Hips (cm)"]
      },
      {
          "styleType" : "Womens",
          "sizes" : [
            {
              "sizeType" : "XS",
              "measures" : ["76-83", "60-67"]
            },
            {
              "sizeType" : "S",
              "measures" : ["83-90", "67-74"]
            },
            {
              "sizeType" : "M",
              "measures" : ["90-97", "74-81"]
            },
            {
              "sizeType" : "L",
              "measures" : ["97-104", "81-88"]
            },
            {
              "sizeType" : "XL",
              "measures" : ["104-114", "88-98"]
            }
          ],
          "sizesVariants" : ["Bust (cm)", "Waist (cm)"]
      }
    ]
  }
]

export default clubShirtsList;