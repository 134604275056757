import _range from 'lodash/range';

import Confetti from './Confetti';

class Stage {

  /**
   * Generate a random number between two values.
   *
   * @param a
   * @param b
   * @param factor
   * @returns {*}
   */
  static randomFrom(a, b, factor) {
    if (!factor) {
      factor = 1;
    }
    return a + (Math.floor((b - a) * Math.random() * factor) / factor);
  }

  constructor() {

    if (this._element === null) {
      return;
    }

    this.nodeCount = 500;
    this._animate = window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        setTimeout(callback, 1000 / 30); // 30 fps
      };

    this._angle = 0.01;
    this._animate = this._animate.bind(window);

    this.draw = this.draw.bind(this);
    this.updatePosition = this.updatePosition.bind(this);

    this.drawGold = this.drawGold.bind(this);
  }

  /**
   * Instigate the class
   */
  init(config = {}) {
    const _this = this;
    const id_elemeent = (config.nodeIdContainer !== undefined && config.nodeIdContainer !== "" ? config.nodeIdContainer : "confetti-container");
    const id_canvas = (config.nodeIdCanvas !== undefined && config.nodeIdCanvas !== "" ? config.nodeIdCanvas : "confetti-canvas");

    this._element = document.getElementById(id_elemeent);
    this._canvas = document.getElementById(id_canvas);

    if (this._element === null || this._canvas === null) {
      return;
    }

    this._context = this._canvas.getContext('2d');
    this._width = this._element.offsetWidth;
    this._height = this._element.offsetHeight;

    // Update configs based on props.
    Object.keys(config).forEach((prop) => {
      _this[prop] = config[prop];
    });

    window.addEventListener('resize', this.setDimensions.bind(this));
    this.setDimensions();

    this.particles = _range(0, this.nodeCount).map(() => {
      return new Confetti({
        color: _this.color,
        numberOfParticles: _this.nodeCount,
        canvas: this._canvas,
        x: _this.constructor.randomFrom(0, _this._canvas.width),
        y: _this.constructor.randomFrom(0, _this._canvas.height),
        r: _this.constructor.randomFrom(5, 35),
        tilt: _this.constructor.randomFrom(-10, 0),
        tiltAngle: 0,
        tiltAngleIncrement: _this.constructor.randomFrom(0.05, 0.12, 100),
      });
    });
    
    if(!_this.typeGold){
      this.step(this.particles, {
        angle: 0.01,
        tiltAngle: 0.1,
      })();
    } else {
      this.stepGold(this.particles, {
        angle: 0.01,
        tiltAngle: 0.1,
      })();
    }
    
  }

  /**
   * Set the dimensions for the canvas.
   */
  setDimensions() {
    if (!document.body.contains(this._element) || !document.body.contains(this._canvas)) {
      this.destroy();
      return;
    }

    this._width = this._element.offsetWidth;
    this._height = this._element.offsetHeight;
    this._canvas.width = this._width;
    this._canvas.height = this._height;
  }

  /**
   * @param particles
   * @param stepConfig
   * @returns {animator}
   */
  step(particles, stepConfig) {
    const _this = this;

    return function animator() {
      if (_this.halt) {
        return;
      }
      _this._context.clearRect(0, 0, _this._width, _this._height);
      if (_this.updateState) {
        _this.updateState();
      }
      for (let i = 0; i < particles.length; i += 1) {
        _this.draw(particles[i], i);
        _this.updatePosition(particles[i], i);
      }
      window.Confetti._animate(_this.step(particles, stepConfig));
    };
  }

  /**
   * @param particlesG
   * @param stepConfigG
   * @returns {animator}
   */
   stepGold(particlesG, stepConfigG) {
    const _this = this;
    
    return function animator() {
      if (_this.halt) {
        return;
      }
      _this._context.clearRect(0, 0, _this._width, _this._height);
      if (_this.updateState) {
        _this.updateState();
      }
      for (let i = 0; i < particlesG.length; i += 1) {
        _this.drawGold(particlesG[i], i);
        _this.updatePosition(particlesG[i], i);
      }
      window.Confetti._animate(_this.stepGold(particlesG, stepConfigG));
    };
  }

  /**
   * Remove the event listeners from the element.
   */
  destroy() {
    const _this = this;
    window.removeEventListener('resize', _this.setDimensions);
    _this.halt = true;
    //_this._context.clearRect(0, 0,  _this._canvas.width,  _this._canvas.height);
  }

  /**
   * @param confetti - The particle object
   * @param index - The index of the particle
   */
  updatePosition(confetti, index) {
    const confettiNode = confetti;

    confettiNode.tiltAngle += confettiNode.tiltAngleIncrement;
    confettiNode.y += (Math.cos(this._angle + confettiNode.d) + 1 + (confettiNode.r / 2)) / 4;
    confettiNode.x += Math.sin(this._angle);
    confettiNode.tilt = 15 * (Math.sin(confettiNode.tiltAngle - (index / 3)));

    // IF the flake is still rendered inside the canvas.
    if (confettiNode.isFlakeExiting()) {
      if (index % 5 > 0 || index % 2 === 0) {
        confettiNode.x = this.constructor.randomFrom(0, this._canvas.width);
        confettiNode.y = -10;
        confettiNode.tilt = this.constructor.randomFrom(-10, 0);
      } else if (Math.sin(this._angle) > 0) {
        confettiNode.x = -5;
        confettiNode.y = this.constructor.randomFrom(0, this._canvas.height);
        confettiNode.tilt = this.constructor.randomFrom(-10, 0);
      } else {
        confettiNode.x = this._canvas.width + 5;
        confettiNode.y = this.constructor.randomFrom(0, this._canvas.height);
        confettiNode.tilt = this.constructor.randomFrom(-10, 0);
      }
    }
  }

  /**
   *
   * @param confetti
   */
  draw(confetti) {
    var gradient = this._context.createLinearGradient(0, 0, 1000, 0);
    
    gradient.addColorStop("0", "#2D165C");
    gradient.addColorStop("0.1" ,"#b05cd3");
    gradient.addColorStop("0.2" ,"#2D165C");
    gradient.addColorStop("0.3" ,"#b05cd3");
    gradient.addColorStop("0.4" ,"#2D165C");
    gradient.addColorStop("0.5" ,"#b05cd3");
    gradient.addColorStop("0.6" ,"#2D165C");
    gradient.addColorStop("0.7" ,"#b05cd3");
    gradient.addColorStop("0.8" ,"#2D165C");
    gradient.addColorStop("0.9" ,"#b05cd3");
    gradient.addColorStop("1.0", "#5d24b3");

    this._context.beginPath();
    this._context.lineWidth = confetti.r / 1;
    this._context.strokeStyle = gradient;
    this._context.moveTo(confetti.x + confetti.tilt + (confetti.r / 4), confetti.y);
    this._context.lineTo(confetti.x + confetti.tilt, confetti.y + confetti.tilt + (confetti.r / 6));
    this._context.stroke();
  }

  /**
   *
   * @param confettiG
   */
   drawGold(confettiG) {
    var gradientGold = this._context.createLinearGradient(0, 0, 1000, 0);
    
    gradientGold.addColorStop("0", "#EBC373");
    gradientGold.addColorStop("0.1" ,"#6643A1");
    gradientGold.addColorStop("0.2" ,"#EBC373");
    gradientGold.addColorStop("0.3" ,"#f9f3e3");
    gradientGold.addColorStop("0.4" ,"#EBC373");
    gradientGold.addColorStop("0.5" ,"#6643A1");
    gradientGold.addColorStop("0.6" ,"#EBC373");
    gradientGold.addColorStop("0.7" ,"#f9f3e3");
    gradientGold.addColorStop("0.8" ,"#EBC373");
    gradientGold.addColorStop("0.9" ,"#6643A1");
    gradientGold.addColorStop("1.0", "#EBC373");

    this._context.beginPath();
    this._context.lineWidth = confettiG.r / 1;
    this._context.strokeStyle = gradientGold;
    this._context.moveTo(confettiG.x + confettiG.tilt + (confettiG.r / 4), confettiG.y);
    this._context.lineTo(confettiG.x + confettiG.tilt, confettiG.y + confettiG.tilt + (confettiG.r / 6));
    this._context.stroke();
  }

  /**
   *
   */
  updateState() {
    this.angle += 0.01;
    this.tiltAngle += 0.1;
  }

}

export default window.Confetti = new Stage();
