import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory, useParams } from 'react-router-dom';

import analyticsService from 'services/analyticsService';
import useEffectOnce from 'hooks/useEffectOnce';
import useSettingsContext from 'contexts/useSettingsContext';
import accountService from 'services/accountService';
import accountLoginValidator from 'validators/accountLoginValidator';
import SettingsContext from 'contexts/SettingsContext';

import Button from 'components/Button/Button';
import TextField from 'components/Forms/TextField';
import Link from 'components/Link/Link';
import HiddenField from 'components/Forms/HiddenField';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';
import Form, { createOnChangeHandler } from './Form';

import './Form.scss';

export default (props) => {
  let { token } = useParams();
  let history = useHistory();

  let settingsContext = useSettingsContext();

  let [isSubmitting, setIsSubmitting] = useState(false);
  let [settings, setSettings] = React.useContext(SettingsContext);
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [country, setCountry] = useState("");
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });

  const createCommand = () => {
    return {
      email,
      password,
      country: settings.country
    };
  }

  const onFormSuccess = async (result) => {
    // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)
    analyticsService.logEvent("forms", "Forms", {
      action: "EmailCapture",
      email
    });
    analyticsService.logEvent("forms", "Forms", {
      action: "LoginSuccess"
    });

    // Update csfr token and flag as logged in
    setSettings({ 
      ...settings, 
      isLoggedIn: true, 
      csrfToken: result.csrfToken,
      country: result.country,
      account: result.account
    });

    setIsSubmitting(true);

    //Send Home.
    history.push(`/`);
  }

  const onFormFail = (error) => {
    analyticsService.logEvent("forms", "Forms", {
      action: "LoginFail",
      label: error
    });
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
  }

  /* RENDER */
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Login | Cadbury FC</title>
    </Helmet>
   
    <section className="layout-general layout-general--logo-pattern layout-wrapper">
        <div className="layout-container layout-container--slim layout-container--center">
          
          <div className="form__intro">
            <h1 className="type-h1">Log in</h1>
            <p>Don't have an account yet? <a href="https://www.cadburyfc.com/registration">Create an account</a></p>
          </div>

          <Form 
            id="login"
            submitTitle="Log in"
            isSubmitting={isSubmitting}
            onSuccess={onFormSuccess}
            onFail={onFormFail}
            command={{
              model: createCommand,
              service: accountService.login
            }}
            validation={{
              validator: accountLoginValidator,
              onValidation: setValidation
            }}
            policyContent={<><p className="text-center"><Link route to="/forgotten-password">Forgotten your password?</Link>.</p></>} 
          >
            
            { validation.errors.hasErrors && validation.acknowledged &&
              <ValidationSummary 
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
            }

            <fieldset className="form__fieldset">
              <TextField 
                focus
                label="* Email address"
                id="email"
                onChange={createOnChangeHandler(validation, 'email', setEmail)}
                value={email}
                hasErrors={!!validation.errors.email}
         
              />
              <ValidationMessage errors={validation.errors.email} />
            </fieldset>

            <fieldset className="form__fieldset">
              <TextField 
                label="* Password"
                id="password"
                type="password"
                onChange={createOnChangeHandler(validation, 'password', setPassword)}
                value={password}
                hasErrors={!!validation.errors.password}
              />
              <ValidationMessage errors={validation.errors.password} />
            </fieldset>
          <fieldset className="form__fieldset">
          <HiddenField 
          
              id="country"
              onChange={createOnChangeHandler(validation, 'country', setCountry)}
              value={country}
            
              hasErrors={!!validation.errors.country}
            />  
          </fieldset>
            

            <ReCaptcha captchaSettings={settingsContext.captchaSettings} />

            { validation.errors.hasErrors && !validation.acknowledged &&
              <Modal 
                panel 
                hideClose
                icon={AlertIcon}
                alt="Warning sign"
                iconTooltip="Alert"
                onDismiss={onDismissModal}
                onConfirm={onDismissModal}
                title="Oops">
                <ValidationSummary 
                  modal
                  defaultMessage="Please check you have entered all fields correctly."
                  errors={validation.errors}
                  handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
                />
                { validation.deadend ? (
                  <Link title="Back to homepage" lozenge to="/">Back to homepage</Link>
                ):(
                  <Button title="Try again" onClick={onDismissModal} />
                )}
              </Modal>
            }
          </Form>

        </div>
    </section>
  </>
}