import ValidationErrorCollection from './ValidationErrorCollection';

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const EMAIL_REGEX2 = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;

const ALPHA_REGEX = /^[A-Za-z]+$/;
//const NAME_REGEX = /^[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+( [a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+)*$/;
//const NAME_REGEX2 = /^(((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘)))+( (((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘))))*$/;
const NAME_REGEX3 = /^[a-zA-ZÖØŷöøÿ,',´’,‘\s\-]+$/;

const ADDRESS_REGEX =/^[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ-0-9'´’‘#,\.]+( [a-zA-ZÀ-ÖØ-ŷ-öø-ÿ-0-9'´’‘#,\.]+)*$/;
const NUMBERS_REGEX = /^(?:[+\d].*\d|\d)$/;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;
const EIRCODE_REGEX = /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/;
const POSTCODE_REGEX = /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;
const GENERIC_POSTCODE_REGEX = /^[a-zA-Z0-9]+( [a-zA-Z-0-9]+)*$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('firstName', 'Please enter your first name');
  required('lastName', 'Please enter your last name');
  required('email', 'Please enter your email address');
  required('emailConfirm', 'Please enter your email address confirmation');
  required('terms', 'Please confirm you agree to our terms & conditions');
  required('legalAge', 'Please confirm you are over 18');
  required('recaptcha2', 'Please confirm you are not a robot');
  required('retailerId', 'Please select a valid retailer');

 

  if (command.firstName && !NAME_REGEX3.test(command.firstName)) {
    messages.addError('firstName', 'Please enter a valid first name');
  }

  if (command.lastName && !NAME_REGEX3.test(command.lastName)) {
    messages.addError('lastName', 'Please enter a valid last name');
  }

  // if (command.email && !cachedRegex.exec(command.email)) {
  //   messages.addError('email', 'Please enter a valid email address');
  // }

  if (command.email && !EMAIL_REGEX2.test(command.email)) {
    messages.addError('email', 'Please enter a valid email address');
  }

  if (command.email && command.email.toLowerCase() !== command.emailConfirm.toLowerCase()) {
    messages.addError('emailConfirm', 'Please confirm your email address');
  }

  let mobileStrippedOfWhitespace = command.phoneNumber.replace(/\s+/g, '');

  if ((mobileStrippedOfWhitespace === '' || mobileStrippedOfWhitespace.split('').length > 13 || mobileStrippedOfWhitespace.split('').length < 11 || !NUMBERS_REGEX.test(mobileStrippedOfWhitespace))) {
    messages.addError('phoneNumber', 'Please enter a valid mobile number');
  }

  if (command.dob && !command.dob.isValid) {
    messages.addError('dob', command.dob.error || "Please enter a valid date of birth");
  }  

  //console.log(messages);

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
