import React from 'react';
import { Redirect } from 'react-router-dom';
import Registration from './Registration';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import useSettingsContext from 'contexts/useSettingsContext';

export default () => {
  const settings = useSettingsContext();
  const competitionEntryState = useCompetitionEntryContext();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";
  let competitionIsMain = compPhaseId === "main";


if (competitionIsOpen && 
    ((competitionEntryState.batchcode && competitionEntryState.barcode) )
    &&
    (competitionIsMain || competitionIsMopup )
    ) 
  {
    return <Registration />
  } else {
    return <Redirect to='/'/>
  }
}