import React from 'react';
import loggerService from 'services/loggerService';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        loggerService.logError(error, info);

        this.setState({
            hasError: true
        });
    }

    render() {
      if (this.state.hasError) {
        return (
            <section className="layout-general layout-wrapper">
              <div className="layout-container layout-container--wide layout-container--center">
                <h1 className="type-h1">OOPS...</h1>
                <p>Sorry, something seems to have gone wrong.</p>
                <p>
                    <a href="/"><span>Go To Homepage</span></a>
                </p>
              </div>
            </section>
        )
      }
  
      return this.props.children;
    }
  }
  
  export default ErrorBoundary;