


const prizeList = [
  { 
    "id": "ARS", 
    "image": "", 
    "title": "Arsenal FC"
  },
  { 
    "id": "CHE", 
    "image": "", 
    "title": "Chelsea FC" 
  },
  { 
    "id": "LEE", 
    "image": "",
    "title": "Leeds United" 
  },
  { 
    "id": "LIV", 
    "image": "", 
    "title": "Liverpool FC" 
  },
  { 
    "id": "MUN", 
    "image": "", 
    "title": "Manchester United" 
  },
  { 
    "id": "TOT", 
    "image": "", 
    "image2x": "",
    "title": "Tottenham Hotspur" 
  }
]

export default prizeList;