import React from 'react';
import { Helmet } from "react-helmet";
import useEffectOnce from 'hooks/useEffectOnce';
import { useHistory } from 'react-router-dom';
import './Policies.scss';

export default (props) => {
  const history = useHistory();

  useEffectOnce(() => {
    if (history.location.hash.indexOf("products-list") > -1) {
      const section = document.querySelector('#products-list');
      if(section !== null) { section.scrollIntoView({ behavior: 'instant', block: 'start' }); }
    }
  });


  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Terms & Conditions | Cadbury - Hot Chocolate 200</title>
    </Helmet>
    <section className="layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
      <h1 className="type-h1">T&C<span className='lowercase'>s</span></h1>

        <div className='policies'>
          
          <div className='policies__content'>
            <ol>
              <li>
                <p>This promotion is open to the residents of the United Kingdom aged 18 or over, excluding employees of the Promoter, its agents and anyone professionally connected with the promotion. </p>
              </li>
              <li>
                <p>Internet access and a valid personal email address and phone number  are required for entry. Contact details required to create account. Participants personal details shall be used for the management and administration of this promotion and no other purpose, unless you have chosen to Opt In to receive further marketing communication.</p>
              </li>
              <li>
                <p>Purchase is necessary. Please retain an itemised till receipt for each entry that states the time and date of purchase prior to entry, but within the Promotional Period, as this may be required for validation and in order for the winner to receive their Prize. </p>
              </li>
              <li>
                <p>Whilst we want you to enjoy this promotion, we discourage over consumption. Please do not purchase more product than you can reasonably consume to increase your chances of winning. If the Promoter or the Promoters’ agencies suspect that a participant is entering into the promotion in a way that is not consistent with the spirit of the promotion (i.e. several entries in order to increase their chances of winning) then the Promoter reserves the right to block entries from that participant and/or will reserve the right to ask for proof of each and every entry in order to award a prize. The Promoter also reserves the right to disqualify that person’s entries and void any prize award.</p>
              </li>
              <li>
                <p><strong>Promotional Period:</strong> Enter between 10:00 GMT on the 15 January 2024 and 16:59 BST on the 15 April 2024 inclusive.</p>
              </li>
              <li>
                <p>Retailer exclusive draw for entrants that have purchased in Asda. Prize draw to be held on 22nd April 2024.</p>
              </li>
              <li>
                <p>Final draw awarding £200 cash and any unclaimed Tier 2 prizes from the Promotional Period to any entries between 17:00 BST on the 15 April 2024 and 23:59 GMT on the 18 October 2024.  (‘Final Draw Period’)</p>
              </li>
              <li>
                <p>No responsibility will be accepted for any entries lost, mislaid, delayed or not included in the promotion for any reason whatsoever. Proof of sending is not proof of receipt.</p>
              </li>
              <li>
                <strong>To Enter: </strong>
                <p>Purchase a Participating Product, see clause 14 for a list of Participating Products, visit chunk.cadbury.co.uk, complete the online form providing the barcode and batch code from your product along with your details (full name, email address and phone number) and select which retailer you purchased in. 
                </p>
              </li>
              <li>
                <strong>Prizes:</strong>
                <ol type='a'>
                  <li>
                    <p><strong>Promotional Period: </strong></p>
                    <p>Tier 1 – 5 x £2,000 cash prize and a Cadbury limited edition vintage-style chunk mug to be won.</p>
                    <p>Tier 2 – 200 x Cadbury limited edition vintage-style chunk mug</p>
                  </li><br></br>
                  <li>
                    <p><strong>Retailer Exclusive Draw</strong></p>
                    <p>In addition to the Tier 1 and Tier 2 prizes, Asda shall make available a further prize exclusively for Asda customers, which can be won in addition to a Tier 1 and Tier 2 Prize and details of such prizes set out below:</p>
                    <p>Asda  - 1 x £2,000 cash prize and a Cadbury limited edition vintage-style chunk mug to be won</p>
                  </li><br></br>
                  <li>
                    <p><strong>Final Draw</strong></p>
                    <p>1 x £200 cash prize to be won and any unclaimed Tier 2 Cadbury limited edition vintage-style chunk mug from the Promotional Period will be reawarded. Where all mugs have been claimed in the Promotional Period no Tier 2 prizes will be awarded.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                Please note that the image shown on promotional communication is for illustrative purposes and the Cadbury limited edition vintage-style chunk mug you receive may vary.                
                </p>
              </li>
              <li>
                <p>
                Entries are limited to a maximum of one entry per person per day and a maximum of ten entries per person throughout the Promotional Period.  An itemised till receipt per entry may be required for verification purposes. Each till receipt must pre-date and time that of the corresponding entry. Maximum of one Tier 1 & one Tier 2 prize per person.                
                </p>
              </li>
              <li>
                <p>
                A person is defined by name, address, phone number and email address and the combination needs to be unique to a single participant for a prize claim to be valid. Postal addresses used to claim may be checked and verified via Royal Mail PAF for eligibility. Winners are provisional at point of win/claim and subject to prize claim verification to qualify win. (‘Person”).                
                </p>
              </li>
              <li id="products-list">
                <p><strong>Participating Products:</strong>    </p>
                        <br></br>
                        <p>
                        Cadbury Drinking Chocolate 500g
                        <br></br>
                        Cadbury Drinking Chocolate 500g (promotional pack)
                        <br></br>
                        Cadbury Instant Hot Chocolate 300g
                        <br></br>
                        Cadbury Instant Hot Chocolate 300g (promotional pack)
                        <br></br>
                        Cadbury Highlights Milk Choc 180g
                        <br></br>
                        Cadbury Bournville Cocoa 250g
                        <br></br>
                        Cadbury Instant Hot Chocolate 250g price marked pack £2.89* (*retailers are free to set their own prices)
                        </p>
              </li>
              <li>
                <strong>Winner Selection</strong>
                <ol type='a'>
                  <li>
                    <strong>Tier 1 Prize Draw Winner Selection</strong>
                    <p>Tier 1 Prize Draw: After the close date of the Promotional Period a  prize draw will be conducted by an independent third party using a computer generated automated system to fairly and randomly select 5 x £2,000 cash prize winners. The Tier 1 winners will also receive a Cadbury limited edition vintage-style chunk mug.</p> 
                  </li>
                  <li>
                    <strong>Tier 2 Winner Selection</strong>
                    <p>Tier 2 winners will be selected by pre-determined winning moments, which have been securely selected by a computer-generated algorithm and will be randomly seeded throughout the Promotional Period. Entrants who submit their entry on or first after one of these winning moment times will win a Prize. Prizes are distributed in the order determined by the winning moments and roll over, so if no one makes a valid entry on or after a winning moment and another winning moment passes before the next valid entry, the available Prize for the first winning moment will be won. E.g. If a winning moment is set for 23:59 one day, an entry submitted on 00.01 the next day will capture that winning moment and win a prize. If there are no entries received within a given period between two or more winning moments, the first unclaimed Prize will be rolled forward and awarded to the next available entrant. Once an entrant has submitted an entry, the website page will inform them whether they are a winner or not. If any Tier 2 prizes from the Promotional Period remain unclaimed at 16:59 on 15 April 2024 they will be added to the Final Draw. </p>      
                  </li>
                </ol>
              </li>
              <li>
                <strong>Retailer Exclusive Draw:</strong>
                <p>
                For those entering between 10:00 GMT on the 15 January 2024 and 16:59 BST on the 15 April 2024 inclusive you will also be asked to select which retailer you purchased from:                 </p>
                <p>
                Asda Shoppers: If you have purchased your Participating Product from Asda, choose Asda from the drop-down menu on the website to be entered into an additional retailer exclusive prize draw outlined in clause 18. Please retain your receipt, as proof of purchase may be required to redeem your prize.   
                </p>
              </li>
              <li>
              <strong>Final Draw: </strong>
                <p>
                For those entering between 17:00 BST on the 15 April 2024 and 23:59 GMT on the 18 October 2024 (‘Final Draw Period’) there will be 1 final draw to win £200 (“Final Draw”) and any unclaimed Tier 2 prizes from the Promotional Period. Winners will be selected via an independently verified random computer draw process conducted by an Independent third party from all valid entries correctly submitted in accordance with these terms and conditions received within the Final Draw Period. The draw will take place within 7 days of the Final Draw closing date. Only one set of winners will be contacted in the final draw.
                </p>
              </li>
              <li>
                <p>
                <strong>Draw Dates:</strong>
                </p>
              </li>

            <table>
              <thead>
                <tr>
                  <th>Draw</th>
                  <th>Entries From</th>
                  <th>Entries Close</th>
                  <th>Draw Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tier 1 Prize Draw</td>
                  <td>15/01/2024 10:00 GMT</td>
                  <td>15/04/2024 16:59 BST</td>
                  <td>22/04/2024</td>
                </tr>
                <tr>
                  <td>ASDA Prize Draw</td>
                  <td>15/01/2024 10:00 GMT</td>
                  <td>15/04/2024 16:59 BST</td>
                  <td>22/04/2024</td>
                </tr>
                <tr>
                  <td>Final Prize Draw</td>
                  <td>15/04/2024 17:00 BST</td>
                  <td>18/10/2024 23:59 GMT</td>
                  <td>25/10/2024 </td>
                </tr>
              </tbody>
            </table>

            <li>
                <strong>Winner Notification</strong>
                <ol type='a'>
                  <li>
                    <strong>Tier 1 Prize Draw:</strong>
                    <p><strong>Tier 1:</strong> Winners of a £2,000 cash prize and Cadbury limited edition vintage-style chunk mug will be contacted by the phone number they submitted upon entry within 7 days of the 22 April 2024. </p>
                  </li>
                  <li>
                    <strong>Instant Win:</strong>
                    <p><strong>Tier 2:</strong> Winners of a Cadbury limited edition vintage-style chunk mug will find out instantly on 		screen if they have won. Winners will receive an email with a secure link to claim. </p>
                  </li>
                  <li>
                    <strong>Retailer Exclusive Draw:</strong>
                    <p>The winner of the retailer exclusive draw will be contacted by the phone number that they submitted upon entry within 7 days of the 22nd April 2024. </p>
                  </li>
                  <li>
                    <strong>Final Draw: </strong>
                    <p>The winner of the £200 cash prize or winners of any unclaimed Tier 2 prizes awarded in the Final Prize Draw will be contacted by the phone number they submitted upon entry into the promotion.  </p>
                  </li>
                </ol>
            </li>

            <li>
                <p><strong>Prize Acceptance, Verification and Delivery</strong></p><br></br>
                
                  
                    <strong>Prize Draws</strong>
                    <p>Every reasonable attempt will be made to contact the winners. If a winner does not claim their prize within 7 days, the prize is forfeited and the Promoter reserves the right to award the prize to another entrant. Alternative winners may have less time to respond. The Promoter will contact a maximum of 1 reserve winner should the prize remain unclaimed after 7 days.</p>
                    <br></br>
                    <strong>Tier 2 prizes</strong>
                    <p>Any Tier prizes that remain unlclaimed after 7 days will be awarded to in the Final Draw. Any prizes that 	remain unclaimed after the Final Draw winners have been contacted will be forfeited.</p>
                    <br></br>
                    <ol type='a'>
                      <li>
                        <strong>Tier 1 Prize Draw</strong>
                        <p><strong>Tier 1:</strong>  Tier 1 prize winners will be required to provide their name, address and bank account  details in order to receive their prize. Upon acceptance the cash prize will be transferred to the winners bank account and the Cadbury limited edition vintage-style chunk mug will be sent to the winners address. Prizes will be delivered within 28 days of a valid claim. </p>
                      </li>
                      <li>
                        <strong>Instant Win</strong>
                        <p><strong>Tier 2:</strong>  Winners of a Cadbury limited edition vintage-style chunk mug will need to claim their prize by clicking on the claim link in the email they receive. Winners will have 7 days to claim their prize from the date they receive the winning email notification. If you have won a Cadbury limited edition vintage-style chunk mug, your prize will be delivered to the address provided upon claim within 28 days of your prize claim. Please note that the Cadbury limited edition vintage-style chunk mug will not go through your post box so please insert a delivery address where someone will be in to accept the delivery of your Prize. The Promoter will not dispatch any prizes to virtual addresses such as, but not limited to AddressPal, Mail Boxes etc, Ghost Mail or OOH Pod.</p>
                      </li>
                      <li>
                        <strong>Retailer Exclusive Draw</strong>
                        <p>The winner of the Asda £2,000 cash prize will be required to provide their name, address and bank account  details in order to receive their prize. Upon acceptance the cash prize will be transferred to the winners bank account and the Cadbury limited edition vintage-style chunk mug will be sent to the winners address. Prizes will be delivered within 28 days of a valid claim.  </p>
                      </li>
                      <li>
                        <strong>Final Draw </strong>
                        <p>The winner of £200 cash prize will be required to provide their name, address and bank account  details in order to receive their prize. Upon acceptance of the prize, winners will receive their prize within 28 days.  </p><br></br>
                        <p><strong>Unclaimed Tier 2:</strong> Winners of a Cadbury limited edition vintage-style chunk mug in the Final Draw will need to provide their name and address details in order for their prize to be dispatched. Prizes will be delivered within 28 days.</p>
                      </li>
                    </ol>
                  
                
            </li>
            <li>
                <p>Prizes will be awarded subject to entry and claim verification by the Promoters agent. Any claims that don’t meet the promotional terms and conditions will receive an email informing them of why their prize claim has been invalidated. </p>
            </li>
            <li>
                <p>Winners of cash prizes must have a valid registered UK bank account. Bank transfer payments will only be made payable to the name of the winning entrant.  </p>
            </li>
            <li>
                <p>For the avoidance of doubt, it is the responsibility of the participant to check junk/spam to ensure they are in receipt of promotional communication. The Promoter is not responsible for an entrant making a late claim on their win if this email is missed for any reason. </p>
            </li>
            <li>
                <p>It is the responsibility of the prize winner to supply the correct personal information and the correct postal address where applicable. The Promoter will not be liable for non-delivery of prizes caused by the provision of wrong information, postal disputes or other causes beyond its control.</p>
            </li>

            <h2  class="type-h2 text-center">General</h2>

            <li>
                <p>The Promoter accepts no responsibility for late, incomplete, incorrectly submitted, corrupted or misdirected entries, claims or correspondence whether due to error, omission, alteration, tampering, deletion, theft, destruction, transmission interruption, communications failure or otherwise. </p>
            </li>
            
            <li>
                <p>No third party or bulk entries. Entries via third parties or syndicates, entries by macros or other automated means and entries which do not satisfy the requirements of these terms and conditions in full will be disqualified. If it becomes apparent that either a participant or household is using any means to circumvent this condition such as, and without limitation, fraudulently falsifying data, acting fraudulently or dishonestly in the opinion of the Promoter using identities other than their own or any other automated means in order to increase that participant's entries or otherwise acting in violation of these terms, these participants will be disqualified, and any prize entitlement will be void.</p>
            </li>

            <li>
                <p>All winners may be requested to participate in reasonable promotional activity (such as publicity and photography) surrounding the winning of the prize, free of charge, and they consent to the Promoter using their name and images in promotional material. Winners are under no obligation to agree to this clause 27 and it is not a condition of prize acceptance. At all times winners can opt-out.</p>
            </li>

            <li>
                <p>By entering this promotion, the winner grants the Promoter the absolute right and permission to use, publish, publicly display, perform, transmit, exhibit and reproduce their name, statements, video, voice, recordings, interviews, photographs or other visual or audible representations or likeness, in whole or in part, individually or in conjunction with other material, including without limitation, text, photographs or images in any medium (whether now known or hereafter invented) including, but not limited to the internet and other electronic and social media and for any and all purposes, publicity, promotion, packaging and trade throughout the world without restriction as to manner, frequency or duration of use or any other purpose whatsoever in connection with this promotion or in connection with the marketing of the products of the Promoter, or otherwise. The winner further agrees that all materials produced pursuant to the rights set out above shall and will remain the property of the Promoter. Winners are under no obligation to agree to this clause 28 and it is not a condition of prize acceptance. At all times winners can opt-out by writing to Cadbury Hot Chocolate Winners List (7971), PO Box 906, Chilton, Aylesbury, HP22 9HR or by emailing <a href="mailto:cadburyhc@promotions.uk.com" target="_blank">cadburyhc@promotions.uk.com</a> within 1 months of the Closing Date of the Promotional Period and within 2 months of the Final Draw Closing Date.</p>
            </li>

            <li>
                <p>The Promoter and its associated agencies and companies will not be liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person’s negligence) in connection with this promotion or accepting or using the prize (including to any guests), except for any liability which cannot be excluded by law (including personal injury, death and fraud) in which case that liability is limited to the minimum allowable by law. </p>
            </li>

            <li>
                <p>The prizes are not transferable or exchangeable and cannot be redeemed for cash or any other form of compensation, this also includes the selling of prizes to third parties. In the event for any reason the prize or any element of the prize at the time stipulated by the Promoter, then that element of the prize will be forfeited by the winners and neither cash nor any other form of compensation will be supplied in lieu of that element of the prize. If for any reason a prize is not available, the Promoter reserves the right to substitute another prize for it, in its sole discretion, of equal or higher value if circumstances beyond the Promoter’s control makes it necessary to do so. </p>
            </li>

            <li>
                <p>If for any reason any aspect of this promotion is not capable of running as planned, including by reason of infection by computer virus, network failure, bugs, tampering, unauthorised intervention, fraud, technical failures or any cause beyond the control of the Promoter which corrupts or affects the administration, security, fairness, integrity or proper conduct of this promotion, the Promoter may in its sole discretion cancel, terminate, modify or suspend the promotion, or invalidate any affected entries. </p>
            </li>

            <li>
                <p>The Promoter seeks to run fair and secure promotions and prevent abuse and cheating. If you enter in a way that is not consistent with these Terms & Conditions, your entry or entries (and any of your associated aliases) will be disqualified, any Prize awarded may be void and recoverable, and we reserve the right to bar you from entering future promotions of ours for a period of at least six months.  For this reason, we reserve the right at any point to:</p>
                <ol type='a' start="5">
                  <li>
                    Verify the eligibility of entrants and/or provisional winners by requesting such information we consider reasonably necessary for this purpose. Entry or a prize may be withheld unless and until verification is completed satisfactorily.
                  </li>
                  <li>
                    Disqualify entries that are not made directly by the individual entering the Promotion. 
                  </li>
                  <li>
                    Disqualify entries made using anonymous email services such as, but not limited to, GuerillaMail, Dispostable, Mailinator or Apple’s “Hide My Email” feature. 
                  </li>
                  <li>
                    Disqualify spam, bulk entries from individuals, trade, consumer groups or third parties, incomplete entries and entries submitted by macros or other automated means. 
                  </li>
                  <li>
                    Disqualify entries beyond the maximum allowed, or those using techniques such as ‘script’, ‘brute force’, multiple SIM cards for Text entries, masking identity by manipulating IP addresses, using aliases or identities other than their own or any other means, fraudulently falsifying data, acting fraudulently or dishonestly. 
                  </li>
                  <li>
                    Disqualify entries which, in some other way, do not fully meet the requirements of these Terms & Conditions. 
                  </li>
                  <li>
                    If a winning entry from a promotion is deemed not to comply with these conditions of entry, the entry will be discarded and the prize will be forfeited. 
                  </li>
                </ol>
            </li>

            <li>
                <p>The surnames and counties of residence of all prize winners from the promotion will be available to anyone who requests them in writing and by enclosing a correctly stamped, self-addressed envelope to  ‘CHC 200’ to the following Address: Cadbury Hot Chocolate Winners List (7971), PO Box 906, Chilton, Aylesbury, HP22 9HR or by emailing <a href="mailto:cadburyhc@promotions.uk.com" target="_blank">cadburyhc@promotions.uk.com</a> within 2 months of the Closing Date of the Promotional Period and within 2 months of the Final Draw Closing Date. The winners may object to their surname and county of residence being published or request for the amount of information being published to be reduced by contacting us in accordance with our Privacy Policy. However, the Promoter shall provide the surname and county of the winners to the competent authorities upon request from such competent authorities (including the Advertising Standards Authority).</p>
            </li>
            <li>
                <p>The Promoter will not be liable for any delay or failure to comply with its obligations for reasons beyond its reasonable control arising from but not limited to Acts of God, global or regional epidemic or pandemic, adverse weather conditions, fire, industrial dispute, war, terrorist activity, hostilities, political unrest, riots, civil commotion, plague or other natural calamities, or any other circumstances of the Promoter.</p>
            </li>
            <li>
                <p>These terms and conditions are governed by English Law and shall be subject to the exclusive jurisdiction of the court of England & Wales.</p>
            </li>
            <li>
                <p>The Promoter shall comply with all applicable requirements of the Data Protection Act 2018, the General Data Protection Regulation 2016 and any successor legislation or other applicable law. The personal information entrants provide will be used by the Promoter for the purpose of conducting this promotion and in accordance with the Promoters’ Data Privacy Notice which can be found at <a href="https://www.cadbury.co.uk/privacy" target="_blank" rel="noopener noreferrer">www.cadbury.co.uk/privacy</a>. The Promoter may disclose entrants’ personal information to its contractors and agents to assist in conducting this Promotion (including any relevant promotional activity) or communicating with entrants.</p>
            </li>

            <strong>Promoter Details:</strong> <p>UK: Mondelez Europe Services GmbH – UK Branch whose address is at Cadbury House, Sanderson Road, Uxbridge, UB8 1DH.</p>
            
            </ol>
          </div>
        </div>
      </div>
    </section>
  </>;
}