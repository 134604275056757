import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Accordion from 'components/Accordion/Accordion';
import './FAQs.scss';
import { HashLink as Link } from 'react-router-hash-link';


export default (props) => {
  let defaultFaq = { section: 1, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#','').split('-');
    defaultFaq = {
      section: parseInt(values[0]),
      index: parseInt(values[1])
    }
  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index && 
        index.section === accordion.section) index = { section: 0, index: 0 };
    setAccordion(index);
  }

  const renderFaqs = (section, faqs) => {
    return faqs.map((faq, index) => {
      let id = { section, index };
      let key =  `${id.section}-${id.index}`;

      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={faq.question}
          answer={faq.answer}>
        </Accordion>
      )
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FAQs | Cadbury - Hot Chocolate 200</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h1">FAQ<span className="lowercase">s</span></h1>
       {  <div className="faqs">
          <div className="faqs__section">
      
          
            <h2 className="type-h2">General promotional questions</h2>
            {
              renderFaqs(1, [{
                question: "1. What is the Cadbury Hot Chocolate 2024 promotion?",
                answer: `
                  <p>To celebrate Cadbury turning 200 years the Hot Chocolate team would like to offer consumers the chance to win a chunk of history by offering them a chance to win a Cadbury limited edition vintage-style chunk mug or a £2,000 cash prize.  There are 200 Cadbury limited edition vintage-style chunk mugs up for grabs and 5 £2,000 cash prizes. </p>
                  
                `},{
                question: "2. Who is the promotion open to?",
                answer: `
                  <p>The promotion is open to the residents of the United Kingdom aged 18 or over, excluding employees of the promoter, its agents and anyone professionally connected to the promotion.</p>
                
                `},{
                  question: "3. How can I enter? / How does it work?",
                  answer: `
                  <p>Enter between 10:00 GMT on the 15th January 2024 and 16:59 BST on the 15th April 2024. Purchase a Participating Product, visit <a href='https://chunk.cadbury.co.uk/' target="_blank" rel="noopener noreferrer">chunk.cadbury.co.uk</a> , complete the online form providing the barcode and batch code from your product along with your details (full name, email address and phone number) and select which retailer you purchased in. You will then find out instantly if you have won a Cadbury limited edition vintage-style chunk mug and will also be entered into the prize draw for a chance to win 1 of 5 £2000 cash prizes. </p>
                    
                `},{
                  question: "4. Which Cadbury products are included in the promotion?",
                  answer: `
                  <p>This promotion includes: </p>
                  <ul>
                  <li>Cadbury Drinking Chocolate 500g </li>
                  <li>Cadbury Drinking Chocolate 500g (promotional pack) </li>
                  <li>Cadbury Instant Hot Chocolate 300g </li>
                  <li>Cadbury Instant Hot Chocolate 300g (promotional pack) </li>
                  <li>Cadbury Highlights Milk Choc 180g </li>
                  <li>Cadbury Bournville Cocoa 250g </li>
                  <li>Cadbury Instant Hot Chocolate 250g price marked pack</li>

                  </ul>
                    
                `},{
                  question: "5. How many times can I enter the promotion?",
                  answer: `
                  <p>Entries are limited to one entry per person per day and a maximum of ten entries per person throughout the promotional period.</p>
                    
                `},{
                  question: "6. Do I need internet access?",
                  answer: `
                    <p>Internet access and a valid personal email address and phone number are required for entry. Participants personal details shall be used for management and administration of this promotion and no other purpose, unless you have chosen to opt in to receive further marketing communication.</p>
                `}
              ])
            }
          </div>
          <div className="faqs__section">
            <h2 className="type-h2">Prize details, claiming and redemption</h2>
            {
              renderFaqs(2, [{
                question: "7. What prizes are available in the Cadbury 'Win a chunk of history' promotion?",
                answer: `
                  <p>Tier 1 – 5 x £2,000 cash prize and a Cadbury limited edition vintage-style chunk mug to be won.</p>
                  <p>Tier 2 – 200 x Cadbury limited edition vintage-style chunk mug.</p>
                  
                  <p><strong>Retailer Exclusive Draw</strong> </br> In addition to the Tier 1 and Tier 2 prizes, Asda shall make available a further prize exclusively for Asda customers, which can be won in addition to a Tier 1 and Tier 2 Prize and details of such prizes set out below:</p>
                  <p>Asda - 1 x £2,000 cash prize and a Cadbury limited edition vintage-style chunk mug to be won.</p>
                  
                  <p><strong>Final Draw</strong></br>
                  There will also be 1 x £200 cash prizes and any unclaimed Cadbury limited edition vintage-style chunk mugs from the Promotional Period to be won in the Final Draw. Please note that if all mugs have been claimed in the Promotional Period no Tier 2 prizes will be awarded.
                  </p>

                  
                `},{
                question: "8. How will I know if I've won?",
                answer: `
                  <p>Tier 1: Winners of a £2,000 cash prize and Cadbury limited edition vintage-style chunk mug will be contacted by the phone number they submitted upon entry within 7 days of 22nd April 2024. </p>
                  <p><strong>Instant Win:</strong></br> Tier 2: Winners of a Cadbury limited edition vintage-style chunk mug will find out instantly on screen if they have won. Winners will receive an email with a secure link to claim.   </p>
                  <p><strong>Retailer Exclusive Draw: </strong></br>   The winner of the retailer exclusive draw will be contacted by the phone number that they submitted upon entry within 7 days of 22nd April 2024.  </p>
                  <p><strong>Final Draw: </strong></br>  The winner of the £200 cash prize and winners of any unclaimed Cadbury limited edition vintage-style chunk mug prizes awarded in the Final Prize Draw will be contacted by the phone number they submitted upon entry into the promotion.   </p>
                
                `},{
                  question: "9. How long do I have to claim my prize?",
                  answer: `
                  <p>If a winner does not claim their prize within 7 days, the prize is forfeited, and the Promoter reserves the right to award the prize to another entrant. Alternative winners may have less time to respond. The Promoter will contact a maximum of 1 reserve winner should the prize remain unclaimed after 7 days.</p>
                  
                `},
              ])
            }
          </div> 

          <div className="faqs__section">
            <h2 className="type-h2">Retailer Exclusive Questions</h2>
            
            {
              renderFaqs(3, [{
                question: "10. Why do I need to choose which retailer I purchased in?",
                answer: `
                  <p>If you purchased in Asda then you will be entered into the Retailer Exclusive prize draw for the chance to win £2,000 cash prize and a Cadbury limited edition vintage-style chunk mug.</p>
                  
                `},{
                question: "11. Which retailers are taking part?",
                answer: `
                  <p>The retailer prize draw is exclusive to Asda.</p>
                
                `},
              ])
            }
          </div>


          <div className="faqs__section">
            <h2 className="type-h2">On Pack Codes & Entry Mechanics</h2>
            
            {
              renderFaqs(4, [{
                question: "12. What codes do I need to enter to participate?",
                answer: `
                  <p>UK residents must enter both the barcode and batch code from your promotional Cadbury product. The Barcode will be on the side for all products; and then the batchcode can be found the bottom of our Cadbury Drinking Chocolate (500g) pack, or on the lid side of our Cadbury Instant Hot Chocolate (400g) or Cadbury Highlights Milk Choc (220g). The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code.</p>
                  
                `},{
                question: "13. I can’t read my code.",
                answer: `
                  <p>In some circumstances the batch code can be illegible or difficult to read. Please contact our customer care team here or call them on <a href='tel:+44800818181' target='_blank' ref='noopener noreferrer'>0800 818181</a> & they'll be able to help.</p>
                  
                `},{
                question: "14. My code isn’t working, am I entering the right code?",
                answer: `
                  <p>It can be confusing as packs often feature many codes on the back of the pack for production reasons. Check out the 'How to enter' section of the site where you will find instructions to find your codes and make sure you're entering it in the correct field on the entry form.</p>
                
                `},{
                  question: "15. I’ve already entered my code; can I enter it again for another chance to win?",
                  answer: `
                  <p>Users are limited to one entry per person, per day, with a maximum of 10 entries during the promotional period. All entries need to be accompanied by a separate purchase. You cannot enter more than once with each promotional product.</p>
                    
                `},{
                  question: "16. My entry is being declined online and I get an error message, what can I do?",
                  answer: `
                  <p>The barcode is on the SIDE of the pack for all products. For Cadbury Drinking Chocolate 500g, the batch code is on the bottom of the pack. For Cadbury Instant Hot Chocolate 400g the batch code is on the lid side.</p>
                    
                `},{
                  question: "17. I entered the wrong email address. What do I do?",
                  answer: `
                  <p>Please call our team on <a href='tel:+44800818181' target='_blank' ref='noopener noreferrer'>0800 818181</a> and we will look into this for you.</p>
                    
                `},
              ])
            }
          </div>
         
        </div>}  
      </div>
    </section>
  </>;
}