import React from 'react';
import { Redirect } from 'react-router-dom';
import Login from './Login';

import useSettingsContext from 'contexts/useSettingsContext';

export default () => {
  const settings = useSettingsContext();



  if (!settings.isLoggedIn) {
    return <Login />
  } else {
    return <Redirect to='/' />
  }
}