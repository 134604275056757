import React from 'react';
import SettingsContext from './SettingsContext';

export default () => {
    const [state, setState] = React.useContext(SettingsContext);

    function setContentHeight(height) {
        setState(state => ({ ...state, contentHeight: height }));
    }

    function setWindowHeight(height) {
        setState(state => ({ ...state, windowHeight: height }));
    }

    function setCountry(country) {
        state.country = country;

        setState(state);
    }

    function setIsClaimSent(claim) {
        setState(state => ({ ...state, isClaimSent: claim }));
    }

    return {
        ...state,
        setContentHeight,
        setWindowHeight,
        setCountry,
        setIsClaimSent
    };
}