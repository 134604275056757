import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useEffectOnce from 'hooks/useEffectOnce';

import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import competitionEntryValidator from 'validators/competitionEntryValidator';

import Button from 'components/Button/Button';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';
import SelectField from 'components/Forms/SelectField';
import DateInput from 'components/Forms/DateInput';


import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';
import Form, { createOnChangeHandler } from './Form';

import './Form.scss';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';
import Link from 'components/Link/Link';

export default () => {
  let history = useHistory();
  let competitionEntryContext = useCompetitionEntryContext();
  let settingsContext = useSettingsContext();
  let compState = settingsContext.competitionStatus.openState;
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [email, setEmail] = useState('');
  let [emailConfirm, setEmailConfirm] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [city, setCity] = useState('');
  let [county, setCounty] = useState('');
  let [postcode, setPostcode] = useState('');
  let [teamCode, setTeamCode] = useState(null);
  let [accountOptIn, setAccountOptIn] = useState(false);
  let [password, setPassword] = useState('');
  let [passwordConfirm, setPasswordConfirm] = useState('');
  let [retailer, setRetailer] = useState(null);
  let [terms, setTerms] = useState(false);
  let [legalAge, setLegalAge] = useState(false);
  let [dob, setDoB] = useState({
    isValid: false
  });
  let [optIn, setOptIn] = useState(false);
  let [teamOptIn, setTeamOptIn] = useState(false);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);

  const [shuffledRetailerList, setShuffledRetailerList] = useState([]);


  let [showValidationModal, setShowValidationModal] = useState(false);

  const otherTeams = ["WLV", "NOT", "MCI", "WBA", "SHF", "BRR", "SHU", "LNC", "CDF"];


  useEffect(()=>{
    
    if(validation.errors.hasErrors && !validation.acknowledged){
      setShowValidationModal(true);
    } else {
      setShowValidationModal(false);
    }
    
  }, [validation])


  useEffect(()=>{
    
    var sett_account = settings.account;
    if(sett_account === null || sett_account === undefined){
      setDoB(null);
    } else {

      if (!sett_account.birthday) {
        setDoB(null);
      }
    }
   
  }, [optIn])
  
  const createCommand = () => {
    let model = {
      firstName,
      lastName,
      email,
      emailConfirm,
      country: "GB",
      phoneNumber,
      dob,      
      retailerId: retailer,      
      terms,
      legalAge,
      isMopupPhase: competitionIsMopup,
      barcode: competitionEntryContext.barcode,
      batchcode: competitionEntryContext.batchcode,
      tier1PrizePublicId: null, //!competitionIsMopup ? competitionEntryContext.prize.id : null,
      teamCode,
      optIn,
      teamOptIn,
      recaptcha2,
      // address1,
      // address2,
      // city,
      // county,
      // postcode,      
    };

    if (dob && dob.isValid) {
      Object.assign(model, {
        dateOfBirthDay: dob.date.getDate(),
        dateOfBirthMonth: dob.date.getMonth() + 1,
        dateOfBirthYear: dob.date.getFullYear(),
        dob
      })
    }

    return model;
  }

  const retailersList = [
    { "value": 2, "name": "Sainsburys"},
    { "value": 3, "name": "Coop"},
    { "value": 4, "name": "Asda"},
    { "value": 5, "name": "Morrisons"},
    { "value": 6, "name": "Tesco"},
    { "value": 7, "name": "Spar"},
    { "value": 8, "name": "Budgens"},
    { "value": 9, "name": "Londis"}
  ];

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }
  

  useEffectOnce(() => {
    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);
    setShuffledRetailerList(shuffle(retailersList));

    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6LfYXRApAAAAAF81VkaLYQjlut6dl_KUIJggeUlw',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
                });
          });
        }
      }catch{}
  }, 1000);
    
  });

  

  const onFormSuccess = (result) => {
  
    // forward to thank-you page
    competitionEntryContext.reset();
    competitionEntryContext.setEmail(email);
    competitionEntryContext.setResult(result);

    // Update csfr token and flag as logged in
    setSettings({...settings, prize: "", isLoggedIn: settings.isLoggedIn? true : accountOptIn });

    //let urlData = !competitionIsMopup ? `/${result.entryPublicId}` : '';
    let urlData = `/${result.entryPublicId}`;
    history.push(`/thankyou${urlData}`);
  }

  const onDismissModal = () => {

    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 

    
  }


  /* RENDER */
  return <>  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Register | Cadbury - Hot Chocolate 200</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h1">Your Details</h1>

        <p className="form__intro">
          Please enter your details below so we know how to contact you, if you are a winner.     
        </p>
        <p className='form__intro'>
          By entering you have the chance to win a Cadbury limited edition vintage-style chunk mug instantly or 1 of 5 £2000 cash prizes awarded via prize draw at the close of the promotion!        
        </p>
        <p className='form__intro'>
          Fields marked with a '*' are mandatory.
        </p>
        <p className='form__intro'>
          If you would like to know more about how we use your personal information visit our&nbsp;
          <Link 
              analytics={{evnt: "siteLink", category: "RegistrationForm", action: "OpenPrivacyPolicy"}}
              href="https://privacy.mondelezinternational.com/eu/en-gb/privacy-notice/" target="_blank"  title="Privacy Policy" >Privacy Policy</Link>.
        </p>
          
        <Form 
          id="registration"
          submitTitle="Submit"
          onSuccess={onFormSuccess}
          formClass="form form--slim"
          command={{
            model: createCommand,
            service: competitionEntryService.addCompetitionEntry
          }}
          validation={{
            validator: competitionEntryValidator,
            onValidation: setValidation
          }}
          submitAnalytics={[
            { evnt: "form", category: "RegistrationForm", action: "EmailCapture", label: email} ,
            (dob && dob.isValid) &&
            { evnt: "form", category: "RegistrationForm", action: "DateOfBirth", label: dob.date} 
          ]}
        >
          
          { validation.errors.hasErrors && validation.acknowledged &&
            <ValidationSummary 
              errors={validation.errors}
              handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
          }

          <fieldset className="form__fieldset" maxlength='10'>
            <TextFieldCharRestricted 
              maxCharLenght ={60}
              label="* First name"
              id="firstName"
              onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
              value={firstName}
              hasErrors={!!validation.errors.firstName}
            />
            <ValidationMessage errors={validation.errors.firstName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted
              maxCharLenght ={60} 
              label="* Last name"
              id="lastName"
              onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
              value={lastName}
              hasErrors={!!validation.errors.lastName}
            />
            <ValidationMessage errors={validation.errors.lastName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted
              maxCharLenght ={60}
              label="* Email address"
              id="email"
              type="text"
              onChange={createOnChangeHandler(validation, 'email', setEmail)}
              value={email}
              hasErrors={!!validation.errors.email}
            />
            <ValidationMessage errors={validation.errors.email} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="* Confirm email address"
              id="emailConfirm"
              autoComplete="off"
              type="text"
              onChange={createOnChangeHandler(validation, 'emailConfirm', setEmailConfirm)}
              value={emailConfirm}
              hasErrors={!!validation.errors.emailConfirm}
            />
            <ValidationMessage errors={validation.errors.emailConfirm} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="* Telephone number"
              id="phoneNumber"
              info="Please ensure this is a valid UK mobile number"
              type="tel"
              onChange={createOnChangeHandler(validation, 'phoneNumber', setPhoneNumber)}
              value={phoneNumber}
              autoComplete="off"
              hasErrors={!!validation.errors.phoneNumber}
              tooltipText="Required so we can contact you should you win"
              onDismiss={onDismissModal}
            />
            <ValidationMessage errors={validation.errors.phoneNumber} />
          </fieldset>

          <fieldset className="form__fieldset"> 
              <SelectField 
                label="* Please select your retailer"
                id="retailerId"
                value={retailer}
                onChange={createOnChangeHandler(validation, 'retailerId', setRetailer)}
                hasErrors={!!validation.errors.retailerId}
                selectAnalytics={{evnt: "form", category: "RegistrationForm", action: "SelectRetailer"}}
              >
                <option defaultValue hidden >Select an option</option>

                {
                  shuffledRetailerList.map((p, index) => (
                    <option value={p.name} key={"retailer-"+index}>{p.name}</option>
                  ))
                }
                <option value="1">Other</option>
                
              </SelectField>
              <ValidationMessage errors={validation.errors.retailerId} />
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="legalAge" 
              label="* I confirm I am over 18 years old."
              hasErrors={validation.errors.hasErrors && !legalAge}
              spaceTop
              onChange={createOnChangeHandler(validation, 'legalAge', setLegalAge)} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckLegalAge"}}
            />
            <ValidationMessage errors={validation.errors.legalAge} />            
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="optIn" 
              label="If you’d like to get the latest Cadbury news, recipes and competitions straight to your inbox, please tick here."
              onChange={setOptIn} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckOptIn"}}
            />

            {
              optIn && 
              <>
              <br/>
                <div className='form__date-entry'>
                  <label
                    className="textfield__label"
                    htmlFor="">
                    If you would like a birthday offer in your inbox, tell us your date of birth (optional). We also use this information to personalise your future experiences with Cadbury.
                  </label>
                  <br/>
                  
                  <DateInput
                    className="date-select"
                    minDate={minBirthDate}
                    minDateErrorMessage="You must be over 18 to enter this promotion"
                    onChange={createOnChangeHandler(validation, 'dob', setDoB)}
                    value={dob}
                  />
                  <ValidationMessage errors={validation.errors.dateOfBirthDay || validation.errors.dob} />
                </div>
                </>
            }
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="teamOptIn" 
              label="Please tick this box to give Cadbury permission to use your personal information to help us ensure our future communications with you, either through our partners or across third party platforms, are more relevant and tailored to you and the preferences you have shared."
              onChange={setTeamOptIn} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckTeamOptIn"}}
            />
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox 
              id="terms" 
              label="* I've read and agree to the Cadbury Hot Chocolate promotion&nbsp;<a href='/terms-and-conditions' rel='noreferrer noopener' target='_blank'>Terms & Conditions</a>&nbsp;and&nbsp;<a href='https://privacy.mondelezinternational.com/eu/en-gb/privacy-notice/' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>."
              hasErrors={validation.errors.hasErrors && !terms}
              onChange={createOnChangeHandler(validation, 'terms', setTerms)} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckTandCs"}}
            />
            <ValidationMessage errors={validation.errors.terms} />    
          </fieldset>
          

          <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
          <div class="g-recaptcha-v2" data-sitekey="6LfYXRApAAAAAF81VkaLYQjlut6dl_KUIJggeUlw"
          data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
          <ValidationMessage errors={validation.errors.recaptcha2} /> 


          { showValidationModal &&
            <Modal 
              panel 
              hideClose
              show={showValidationModal}
              icon={AlertIcon}
              alt="Warning sign"
              onDismiss={onDismissModal}
              onConfirm={onDismissModal}
              title="Oops">
              <ValidationSummary 
                modal
                defaultMessage="Please check you have entered all fields correctly."
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
              { !validation.errors.globalErrors.length ? (
                  <Button title="Try again" onClick={onDismissModal} />
                ) : (
                  <Button title="Back to homepage" href="/" />
                )
              }
            </Modal>
          }
        </Form>

      </div>
    </section>
    
    <SummaryTsandCs />
  </>;
}