import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Claim from './Claim';
import useSettingsContext from 'contexts/useSettingsContext';

export default () => {
  let { token } = useParams();
  let settings = useSettingsContext();
  let isCompetitionOpen = settings.competitionStatus.openState === "Open";

  if (token) {
    return (
      <Claim 
        token={token}
      />
    )
  } else {
   return <Redirect to='/' />
  }

}
