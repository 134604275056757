import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import analyticsService from 'services/analyticsService';
import Link from 'components/Link/Link';

import Stage from 'components/Confetti/Stage';
import timer from 'utils/timer';

import HotChocolateProducts from 'assets/images/campaign/hot-chocolate-products.png';
import HotChocolateProducts2x from 'assets/images/campaign/hot-chocolate-products@2x.png';
import HotChocolateProductsMob from 'assets/images/campaign/hot-chocolate-products-lg-mob.png';
import HotChocolateProductsMob2x from 'assets/images/campaign/hot-chocolate-products-lg-mob@2x.png';
import HotChocolateProductsDesk from 'assets/images/campaign/hot-chocolate-products-Desk.png';
import HotChocolateProductsDesk2x from 'assets/images/campaign/hot-chocolate-products-Desk@2x.png';

import HotChocolateMug from 'assets/images/campaign/hot-chocolate-mug.png'
import HotChocolateMug2x from 'assets/images/campaign/hot-chocolate-mug@2x.png'
import HotChocolateMugMob from 'assets/images/campaign/hot-chocolate-mug-mob.png'
import HotChocolateMugMob2x from 'assets/images/campaign/hot-chocolate-mug-mob@2x.png'
import HotChocolateMugTab from 'assets/images/campaign/hot-chocolate-mug-tab.png'
import HotChocolateMugTab2x from 'assets/images/campaign/hot-chocolate-mug-tab@2x.png'
import HotChocolateMugLgTab from 'assets/images/campaign/hot-chocolate-mug-lg-tab.png'
import HotChocolateMugLgTab2x from 'assets/images/campaign/hot-chocolate-mug-lg-tab@2x.png'
import HotChocolateMugDesk from 'assets/images/campaign/hot-chocolate-mug-desk.png'
import HotChocolateMugDesk2x from 'assets/images/campaign/hot-chocolate-mug-desk@2x.png'
import HotChocolateMugLgDesk from 'assets/images/campaign/hot-chocolate-mug-lg-desk.png'
import HotChocolateMugLgDesk2x from 'assets/images/campaign/hot-chocolate-mug-lg-desk@2x.png'
import HotChocolateMugExtraLgDesk from 'assets/images/campaign/hot-chocolate-mug-lg-desk.png'
import HotChocolateMugExtraLgDesk2x from 'assets/images/campaign/hot-chocolate-mug-lg-desk@2x.png'

import './Thankyou.scss';



export default () => {
   let { entryPublicId } = useParams();

  let [settings, setSettings ] = React.useContext(SettingsContext);
  let settingsContext = useSettingsContext();
  const [country, setCountry] = useState(!!settings.country);

  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";

  let competitionEntryContext = useCompetitionEntryContext();
  let [entry, setEntry] = useState(competitionEntryContext.result);



  useEffect(() => {
    
    async function fetchData() {
      let result = await competitionEntryService.getCompetitionEntry(entryPublicId);
      
      if(result !== null) {
        setEntry(result);   
      } else {
        setEntry(null); 
      }

      
    }
    
    if (entryPublicId && !entry) {
      fetchData();
    }


   var email = competitionEntryContext.email;
    //Had to go here, as not triggered on last page
   if(email != null)
    {
      // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)
      
       analyticsService.logEvent("forms", "Forms", {
        action: "EmailCapture",
         email
       });

       analyticsService.logEvent("forms", "Forms", {
         action: "RegistrationSuccess"
       });

       if (!competitionIsMopup && entry.hasWon) {        
        analyticsService.logEvent('event', 'Prize', {
        action: entry.prizePublicId
        });
      } 
    }
   
    // STARTS CONFETTI 
    var isTab = window.matchMedia("only screen and (min-width: 768px)").matches;
    var isDesk = window.matchMedia("only screen and (min-width: 1300px)").matches;
    var conffetiNodes = 30;

    if(isDesk){
      conffetiNodes = 120;
    } else if(isTab) {
      conffetiNodes = 60;
    } 

    if (entry && entry.hasWon) {
      start();
      return () => Stage.destroy()
    }

    async function confetti() {
      Stage.init({
        nodeCount: conffetiNodes,
        typeGold: true
      });
    }

    async function start() {
      await timer.delay(500);
      confetti();
    }
    // ENDS CONFETTI 
      
  }, [entry]);

 

  if (entryPublicId && !entry) {
    return (
      <div>Loading...</div>
    )
  }

  if (competitionIsOpen && ((entry !== null && !entry.entryPublicId) || !entryPublicId)) {
    return (
      <Redirect to="/" />
    )
  }


  const renderWinner = () => {  
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Congratulations! | Cadbury - Hot Chocolate 200</title>
        </Helmet>
      
      
      <section id="confetti-container" className="layout-general layout-general--full layout-wrapper layout-wrapper layout-general--conffeti">
        <div className="layout-container layout-container--center layout-container--winner thankyou">
          <div class="thankyou__text-container thankyou__text-container--wide">
            <h1 className="type-h1"><span>Congratulations!</span> You've won a chunk of history!</h1>
            <p className="thankyou__para">
              When we said it was an instant win, we meant it!              
            </p>
            <p className="thankyou__para">
              You’ve won a Cadbury limited edition vintage-style chunk mug, subject to prize claim and validation.           
            </p>
            <p className="thankyou__para">
              A confirmation email has been sent to your email address for the next steps in claiming your prize.              
            </p>
            <p className="thankyou__para">
              And more good news, you’re also in with a chance to win 1 of 5 £2000 cash prizes via prize draw at the end of the promotion. Lucky winners will be contacted by the phone number submitted at the point of entry within 7 days of the draw date which will take place on 22nd April 2024.
            </p>

            <Link 
              analytics={{evnt: "siteLink", category: "WinnerPage", action: "OpenCadburyCoUkWebsite"}}
              href="https://www.cadbury.co.uk/" target="_blank" lozenge thankyou title="Go to Cadbury.co.uk Website" >Go to Cadbury.co.uk</Link>

       
            
          </div>
        </div>
        <picture>
              <source media="(min-width: 1800px)" srcSet={HotChocolateMugExtraLgDesk2x + " 2x, " + HotChocolateMugExtraLgDesk + " 1x"} type="image/png" />
              <source media="(min-width: 1500px)" srcSet={HotChocolateMugLgDesk2x + " 2x, " + HotChocolateMugLgDesk + " 1x"} type="image/png" />
              <source media="(min-width: 1300px)" srcSet={HotChocolateMugDesk2x + " 2x, " + HotChocolateMugDesk + " 1x"} type="image/png" />
              <source media="(min-width: 992px)" srcSet={HotChocolateMugLgTab2x + " 2x, " + HotChocolateMugLgTab + " 1x"} type="image/png" />
              <source media="(min-width: 640px)" srcSet={HotChocolateMugTab2x + " 2x, " + HotChocolateMugTab + " 1x"} type="image/png" />
              <source media="(min-width: 430px)" srcSet={HotChocolateMugMob2x + " 2x, " + HotChocolateMugMob + " 1x"} type="image/png" />
              <img className="thankyou__image thankyou__image--mug" src={HotChocolateMug} srcSet={HotChocolateMug2x + " 2x, " + HotChocolateMug + " 1x"} alt="Cadbury Chocolate Chunk Mug" />
        </picture>
        
        <canvas id="confetti-canvas" className="layout-general__confetti" width="100%" height="100%" />
      </section>
      </>
    ); 

  }
  
  const renderLoser = () => {

    return (
      <>
       <Helmet>
          <meta charSet="utf-8" />
          <title>Sorry | Cadbury - Hot Chocolate 200</title>
        </Helmet>
      
      
        <section className="layout-general layout-general--full layout-wrapper">
          <div className="layout-container layout-container--center thankyou">
            <div className="thankyou__text-container">
              <h1 className="type-h1">try again tomorrow?</h1>
              <p className="thankyou__para">
                Unfortunately you haven’t won today, but you can try again tomorrow when you buy another Cadbury Hot Chocolate!            
              </p>
              <p className="thankyou__para">
                The good news is, you’re in with a chance to win 1 of 5 £2000 cash prizes via prize draw at the end of the promotion. Lucky winners will be contacted by the phone number submitted at the point of entry within 7 days of the draw date which will take place on 22nd April 2024.
              </p>
            </div>
        
            
            <Link 
              analytics={{evnt: "siteLink", category: "LoserPage", action: "OpenCadburyCoUkWebsite"}}
              href="https://www.cadbury.co.uk/" target="_blank" lozenge thankyou title="Go to Cadbury.co.uk Website" >Go to Cadbury.co.uk</Link>


            <picture>
              <source media="(min-width: 640px)" srcSet={HotChocolateProductsDesk2x + " 2x, " + HotChocolateProductsDesk + " 1x"} type="image/png" />
              <source media="(min-width: 414px)" srcSet={HotChocolateProductsMob2x + " 2x, " + HotChocolateProductsMob + " 1x"} type="image/png" />
              <img className="thankyou__image" src={HotChocolateProducts} srcSet={HotChocolateProducts2x + " 2x, " + HotChocolateProducts + " 1x"} alt="Cadbury Hot Chocolate products" />
            </picture>
          </div>

        </section>
      </>
    );
  }

  const renderMopup = () => {
    return (
      <>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Thank you | Cadbury - Hot Chocolate 200</title>
        </Helmet>
      
        <section className="layout-general layout-general--full layout-wrapper">
          <div className="layout-container layout-container--center thankyou">
            <h1 className="type-h1">Thank you</h1>
            <p className="thankyou__para">
            You've been entered into the final draw for the chance to win £200 in cash! The draw will take place on 25th October 2024.
            </p>
            <p className="thankyou__para">
            And remember, you can enter again tomorrow! <strong>Good luck!</strong>
            </p>
            <p className="thankyou__para">
            Why not visit the website to check out all our latest competitions…
            </p>

            <Link 
              analytics={{evnt: "siteLink", category: "MopUpPhasePage", action: "OpenCadburyCoUkWebsite"}}
              href="https://www.cadbury.co.uk/" target="_blank" lozenge thankyou title="Go to Cadbury.co.uk Website" >Go to Cadbury.co.uk</Link>

          </div>
        </section>
      </>
    );
  }
  


  return (
    <>
    {
      
      competitionIsMopup ? (
        renderMopup()
      ) : (
        entry.hasWon ? (
        renderWinner()
        ): (
        renderLoser()
        )   
      )

    }
    </>
  );
}
