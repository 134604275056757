import React from 'react';
import classList from 'react-classlist-helper';
import './Button.scss';

export default (props) => {
  const classMap = {
    "button": true,
    "button--wide": !!props.wide,
    "button--text-link" : !!props.analytics,
    [props.className]: !!props.className,
  };

 if (props.href) {
    return (
      <a 
        className={classList(classMap)}
        href={props.href} 
        title={props.title}
      >
        {props.title}
      </a>
    )
  } else {
    return (
  
      <button 
        type={props.type ||"button"}
        className={classList(classMap)}
        disabled={props.disabled}
        onClick={props.onClick}>
          {props.title}
      </button>
    );
  }
}