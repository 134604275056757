import ValidationErrorCollection from './ValidationErrorCollection';

const ALPHA_REGEX = /^[A-Za-z]+$/;
//const NAME_REGEX = /^[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+( [a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+)*$/;
const NAME_REGEX2 = /^(((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘)))+( (((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘))))*$/;
const NAME_REGEX3 = /^[a-zA-ZÖØŷöøÿ,',´’,‘\s\-]+$/;
const EIRCODE_REGEX = /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/;
const POSTCODE_REGEX = /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;
const GENERIC_POSTCODE_REGEX = /^[a-zA-Z0-9]+( [a-zA-Z-0-9]+)*$/;
const ADDRESS_REGEX =/^[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ-0-9'´’‘#,\.]+( [a-zA-ZÀ-ÖØ-ŷ-öø-ÿ-0-9'´’‘#,\.]+)*$/;

function validate(command) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('firstName', 'Please enter your first name');
  required('lastName', 'Please enter your last name');
  required('address1', 'Please enter address line 1');
  required('town', 'Please enter your town');
  required('county', 'Please enter your county');
  required('postcode', 'Please enter your postcode');
  required('recaptcha2', 'Please confirm you are not a robot');
  
  if (command.firstName && !NAME_REGEX3.test(command.firstName)) {
    messages.addError('firstName', 'Please enter a valid first name');
    
  }

  if (command.lastName && !NAME_REGEX3.test(command.lastName)) {
    messages.addError('lastName', 'Please enter a valid last name');
  }
 

  if (command.address1!== '' && !ADDRESS_REGEX.test(command.address1)) {      
    messages.addError('address1', 'Please check address line 1 is a valid address');      
  }

  if (command.address2!== '' && !ADDRESS_REGEX.test(command.address2)) {      
    messages.addError('address2', 'Please check address line 2 is a valid address');
  }

  if (command.town && !NAME_REGEX2.test(command.town)) {
    messages.addError('town', 'Please enter a valid city');
  }

  if (command.county && !NAME_REGEX2.test(command.county)) {
    messages.addError('county', 'Please enter a valid county');
  }


  if (command.postcode && !POSTCODE_REGEX.test(command.postcode)) {
    messages.addError('postcode', 'please enter a valid post code');
  }


  if (command.isCustomPrize) {
    required('product1', 'Please select a product');
    required('product2', 'Please select a product');
    required('product3', 'Please select a product');
    required('product4', 'Please select a product');
    required('product5', 'Please select a product');
    required('product6', 'Please select a product');
  }

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
