import React, { useRef, useEffect } from 'react';
import classList from 'react-classlist-helper';
import useEffectOnce from 'hooks/useEffectOnce';
import './TextField.scss';

export default (props) => {
  const inputRef = useRef();
  const classMap = {
    "textfield": true,
    "textfield--error": props.hasErrors,
  };

  function onChange(event) {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  }

  useEffectOnce(() => {
    if (props.focus) {
      inputRef.current.focus();
    }
  });

  return (
    <div className={classList(classMap)}>
      <input
        className="textfield__input"
        type="hidden"
        id={props.id} 
        name={props.id}
        ref={inputRef}
        autoComplete={props.autoComplete}
        onChange={onChange}
        value={props.value || "" }
        readOnly={props.readonly}
        disabled={props.readonly}
      />
    </div>
  );
}
