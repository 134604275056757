import React from 'react';
import classList from 'react-classlist-helper';
import './Prize.scss';

const Prize = (props) => {
  const classMap = {
    "prize": true,
    "prize--selectable": props.selectable,
    [props.className]: !!props.className,
  };

  const onChange = (e, id) => {
    if (props.onChange) {
      props.onChange(props.prize);
    }
  }

  return (
    <div className={classList(classMap)} key={"prize_" + props.prize.id} htmlFor={"prize_" + props.prize.id}>
      { props.model &&
        <input 
          className="prize__radio" 
          type="radio" 
          id={"prize_" + props.prize.id} 
          name={props.model} 
          value={props.prize.id} 
          checked={props.selected} 
          onChange={(e) => onChange(e, props.prize.id)} 
          tabIndex={props.tab}
        />
      }
      
      <div className="prize__image">
        {
          props.prize.image2x ? (
            <img src={props.prize.image} srcSet={props.prize.image2x + " 2x, " + props.prize.image + " 1x"} alt={props.alt} />  
          ) : (
            <img src={props.prize.image} alt={props.alt} />  
          )
        }
        <div className='prize__fake-radio '><div className="prize__tick"></div></div>
      </div>

      <h3 className={"prize__title"}>{props.prize.title}</h3>

      {
        (props.prize.description !== undefined && props.prize.description !== "") ? (
          <div className="prize__info" dangerouslySetInnerHTML={{__html: props.prize.description}}></div>
        ) :(<></>)
      }
      
    </div>
  )
}

export default Prize;
