import React, { useState } from 'react';
import loggerService from 'services/loggerService';
import useSettingsContext from 'contexts/useSettingsContext';
import reCaptchaService from 'services/reCaptchaService';
import ValidationException from 'validators/ValidationException';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import Button from 'components/Button/Button';

import analyticsService from 'services/analyticsService';

export default (props) => {
  let [isSubmitting, setIsSubmitting] = useState(false);
  let settings = useSettingsContext();

  async function submitForm(e) {
    e.preventDefault();

    let { 
      id,
      command: { model, service }, 
      validation: { validator, onValidation },
    } = props;

    if (isSubmitting) return;

    let result;
    try {
      let isClientValidationSuccessful = runClientValidation(model());

      if (!isClientValidationSuccessful) return;

      result = await runCommand(model());

    } catch (ex) {
      let errors = new ValidationErrorCollection();
      errors.addGenericError();
      onValidation({ errors, acknowledged: false });
      
      loggerService.logError(ex);
    }

    if (result) {
      props.onSuccess(result);
    }

    function runClientValidation(command) {
      let clientValidationResult = validator.validate(command, settings);
      onValidation({ errors: clientValidationResult, acknowledged: false });

      return !clientValidationResult.hasErrors;
    }

    async function runCommand(command) {
      setIsSubmitting(true);

      try {
        command.recaptchaToken = await reCaptchaService.getToken(settings.captchaSettings, id)
        let result = await service(command);

        return result || true;
      }
      catch (exception) {
        if (exception instanceof ValidationException) {

          onValidation({ errors: exception.errors, acknowledged: false });
            return false;
        } else {
            throw exception;
        }
      }
      finally {
        setIsSubmitting(false);
      }
    }
  }

  const addSubmitAnalytics = () => {    
    if(props.submitAnalytics && props.submitAnalytics !== null){
      var analytics = props.submitAnalytics;
      
      if(!Array.isArray(analytics)){
        analyticsService.logEvent(analytics.evnt, analytics.category, {
          action: analytics.action,
          label: (analytics.label ? analytics.label : "")
        });
      } else {
        analytics.forEach(element => {
          if(element){
            analyticsService.logEvent(element.evnt, element.category, {
              action: element.action,
              label: (element.label ? element.label : "")
            });
          }
        });
      }
    }
  }

  return (
    <form 
    id={props.id}
    className={props.formClass || "form"}
      onSubmit={submitForm}>
      {props.children}

      {
        <Button title={isSubmitting ? 'Submitting...' : props.submitTitle || 'Submit' } 
          type="submit" 
          className={"form__submit " + ( props.submitHidden ? " form__submit--hidden" : "") }
          disabled={isSubmitting} 
          onClick={addSubmitAnalytics}
        />
      }

    {
        props.policyContent ?
        <div className="form__privacy">
          {props.policyContent}
        </div>
        :
        ""
      }

    </form>
  )
}

export const createOnChangeHandler = (validation, property, setter, captchaValue = "") => {
  
  if(captchaValue !== ""){
    clearError(validation, property, setter);
    setter(captchaValue);
  } else {
    return (value) => {
      clearError(validation, property, setter);
      setter(value);
    };
  }
}

const clearError = (validation, property, setter) => {
  if (!validation.errors[property]) {
    return;
  }

  validation.errors.clearError(property, setter);
  setter(prevState => ({ ...prevState, ...validation }));
}
