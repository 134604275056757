import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import analyticsService from 'services/analyticsService';
import useCompetitionEntryState from 'contexts/useCompetitionEntryContext';
import ActionBar from 'containers/ActionBar/ActionBar';
import Button from 'components/Button/Button';
import prizeList from 'utils/prizes';
import Prize from 'components/Prize/Prize';
import Carousel from 'components/Carousel/Carousel';

import SettingsContext from 'contexts/SettingsContext';

import './PrizeSelection.scss';

export default () => {
  let competitionEntryState = useCompetitionEntryState();
  let history = useHistory();
  let [prize, setPrize] = useState(competitionEntryState.prize);

  let [settings, setSettings ] = React.useContext(SettingsContext);

  const onContinue = () => {
    if (prize !== null) {
      competitionEntryState.setPrize(prize);
      setSettings({...settings, prize: prize.id});

      analyticsService.logEvent("form", "PrizeSelection", {
        action: "SubmitSelectPrize",
        label: prize.id
      });

      history.push("/registration");
    } 
  }

  const handleSelectedPrize = (selPrize) => {
    setPrize(selPrize);

    analyticsService.logEvent("form", "PrizeSelection", {
      action: "SelectPrize",
      label: selPrize.id
    });
  }


  /* RENDER */
  return <>
    
    <Helmet>
      <meta charSet="utf-8" />
      <title>Prize Selection | Cadbury - Hot Chocolate 200</title>
    </Helmet>
    <div className="layout">
      <ActionBar show={prize !== null}>
        <Button wide title={"Continue"} onClick={onContinue} />
      </ActionBar> 
      <section className={(prize !== null ? "layout-general layout-general--mt0 layout-wrapper": "layout-general layout-wrapper") + " layout-general--full" }>
        <div className="layout-container layout-container--center prize-select " >
          <h1 className="type-h1">Choose your club</h1>
          <p>
            Pick from one of our top 6 clubs for the chance to win an all-inclusive match day experience.
          </p>
          <form>
            <Carousel 
              className="prize-select__list"
              params={{
                spaceBetween: 20,
                slidesPerView: 1,
                slidesPerGroup: 1,
                pagination: true,
                navigation: true,
                breakpoints: {
                  768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 30
                  },
                  1024: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 30
                  },
                }
              }}
            >
            
            {
                prizeList.map((p, index) => (
                  <div className='prize-wrapper' key={"prize-wrapper__" + index}>
                    
                    <Prize 
                      id={p.id}
                      tab={index + 1}
                      prize={p} 
                      model="prize"
                      selected={prize && prize.id === p.id} 
                      selectable 
                      onChange={(selected) => handleSelectedPrize(selected)}
                      alt={p.title + " badge"}
                    />
                  </div>
                ))
              }
   </Carousel>

              
          </form>

        </div>
      </section>
    </div>
  </>;
}