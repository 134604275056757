import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import Home from './Home/Home';
import RegistrationRouteGuard from './Forms/RegistrationRouteGuard';
import ClaimRouteGuard from './Forms/ClaimRouteGuard';
import TermsAndConditionsRouteGuard from './Policies/TermsAndConditionsRouteGuard';
import CookiePolicy from './Policies/Cookies';
import Thankyou from './Thankyou/Thankyou';
import FourOhFour from './Errors/FourOhFour';
import LoginRouteGuard from './Forms/LoginRouteGuard';
import PrizeSelectionRouteGuard from './PrizeSelection/PrizeSelectionRouteGuard';
import ClubShirtSizes from './ClubShirtSizes/ClubShirtSizes';
import FAQsRouteGuard from './FAQs/FAQsRouteGuard';


export default (props) => {
  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route 
        exact 
        key="home"
        path="/">
        <Home />
      </Route>
      <Route 
        exact 
        key="login"
        path="/login">
        <LoginRouteGuard />
      </Route>
      <Route 
        exact 
        key="prize-select"
        path="/prize-select">
        <PrizeSelectionRouteGuard />
      </Route>
      <Route 
        exact 
        key="registration"
        path="/registration">
        <RegistrationRouteGuard />
      </Route>
      <Route 
        exact 
        key="thankyou"
        path="/thankyou/:entryPublicId">
        <Thankyou />
      </Route>
      <Route 
        exact 
        key="thankyou"
        path="/thankyou">
        <Thankyou />
      </Route>
      <Route 
        exact 
        key="faqs"
        path="/faqs">
      <FAQsRouteGuard />
      </Route>
      <Route 
        exact 
        key="terms-and-conditions"
        path="/terms-and-conditions">
        <TermsAndConditionsRouteGuard />
      </Route>
      <Redirect exact from="/cookies" to="/cookie-policy" />
      <Route 
        exact 
        key="cookie-policy"
        path="/cookie-policy">
        <CookiePolicy />
      </Route>
      <Route 
        exact 
        key="claim"
        path="/claim/:token">
        <ClaimRouteGuard />
      </Route>
      <Route 
        exact 
        key="club-shirt-sizes"
        path="/club-shirt-sizes">
        <ClubShirtSizes />
      </Route>
      <Route>
        <FourOhFour />
      </Route>
    </AnimatedSwitch>
  );
}
