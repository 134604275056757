import React from 'react';
import './ClubShirtSizes.scss';

import clubShirtsList from 'utils/clubShirts';

export default (props) => {

  let divBody = clubShirtsList.map((item, index) => (
    <div className='club-shirt-block' key={"club-shirt-block-" + index}>
      <input id={"club-shirt-block__input-" + index} type='checkbox' className='club-shirt-block__input' />
      <label for={"club-shirt-block__input-" + index} className='club-shirt-block__label type-h2'>
        {item.club}
        <div className="club-shirt-block__label__icon">
          <span></span>
          <span></span>
        </div>
      </label>
      
      <div className='club-shirt-block__tables'>
       {
          item.style.map(sitem=>(
            <div className='sizes-table'>
              <div className='sizes-table__type-column'>
                <div className='sizes-table__heading'>
                  {sitem.styleType}
                  </div>
                {
                  sitem.sizesVariants.map(varitem => (
                    <div className='sizes-table__sizetype'>{varitem}</div>
                  ))
                }
              </div>
              <div className='sizes-table__sizes-column'>
                {
                  sitem.sizes.map(ssitem => (
                    <div className='sizes-table__size-block'>
                      <div className='sizes-table__heading'>{ssitem.sizeType}</div>

                      {
                        ssitem.measures.map((smesitem, index) => (
                          
                          <div>
                            <div className='sizes-table__sizetype'>{sitem.sizesVariants[index]}</div>
                            {smesitem}
                          </div>
                        ))
                      }
                    </div>
                  ))
                }

              </div>
            </div>
          ))
       }
       </div>
    </div>
  ));


  return (
    <section className="layout-general layout-wrapper club-shirt-sizes-template">
      <div className="layout-container layout-container--medium layout-container--center">
        <h1 className="type-h1">Club shirt sizes</h1>

        {
          divBody
        }
      </div>
    </section>
  );
}