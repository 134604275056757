import React from 'react';
import classList from 'react-classlist-helper';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './Carousel.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default (props) => {
  const classMap = {
    "carousel": true,
    [props.className]: !!props.className,
  };

  const renderItems = React.Children.map(props.children, (c, i) => {
    const key = c.key ? `key-${c.key}` : `index-${i}`
    return (
      <SwiperSlide key={key} className="carousel__item">
        {c}
      </SwiperSlide>
    );
  });

  return (
    <div className={classList(classMap)}>
      <Swiper
        navigation={props.navigation}
        pagination={{ clickable: true }}
        {...props.params}
      >
        { renderItems }
      </Swiper>
    </div>
  )
}